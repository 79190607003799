import React from 'react';
import autoBind from 'react-autobind';
import Script from 'react-load-script'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

import PlacesAutocomplete, {
  geocodeByAddress,
  //getLatLng,
} from 'react-places-autocomplete';

import posed/*, { PoseGroup }*/ from 'react-pose';
import Select from 'react-select';
import {ResponsiveLine} from '@nivo/line'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import copy from 'copy-to-clipboard';
import Toggle from 'react-toggle'
import Lottie from 'lottie-web';

//import { Textfit } from 'react-textfit'

import Validation from '../DarwinReactLibrary/js/Validation.js';
//import * as Generation from './Generation.js';
import API from './API.js';
import * as Helpers from './Helpers.js';

import ImageSKBlack from './images/SimpleKicking/SKBlack.svg'
import ImageSKWhite from './images/SimpleKicking/SKWhite.svg'
// import ImageMenu from './images/Icons/Menu3White.svg'
// import ImageClose from './images/Icons/CloseWhite.svg'
// import ImageFacebook from './images/Icons/facebook.svg'
// import ImageInstagram from './images/Icons/instagram.svg'
// import ImageSpotify from './images/Icons/spotify.svg'

import ImageZochaWhite from './images/Zocha/ZochaWhite.svg'
//import ImageZochaDark from './images/Zocha/ZochaDark.svg'
import ImageAddPhoto from './images/Icons/AddPhoto.svg'
import ImageHelpIcon from './images/Icons/HelpIcon.svg'
import ImageXButton from './images/Icons/XButton.svg'
import ImageCheckmarkLine from './images/Icons/CheckmarkLineWhite.svg'
import ImageBank from './images/Icons/Bank.svg'
import ImageBankWhite from './images/Icons/BankWhite.svg'
import ImageShareLink from './images/Icons/ShareLink.svg'
import ImageX from './images/Icons/X.svg'

const GoogleMapsStyle = require("./GoogleMapsStyle.json");
require("react-toggle/style.css")

/**
Provide animation - path to the file, (onClick), (className), (loop - default true), (autoplay - default true)
*/
export class LottieAnimation extends React.Component {

  constructor(props) {
    super(props)
    this.svgcontainer = React.createRef()
  }

  componentDidMount() {
    let path = this.props.animation
    Lottie.loadAnimation({
      container: this.svgcontainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: path,
    })
  }

  render() {
    return (
      <div className={"LottieAnimation " + (this.props.className || "")} onClick={this.props.onClick}>
        <div ref={this.svgcontainer} className="LottieAnimationView" />
      </div>
    )
  }
}

export class Table extends React.Component {

  render() {

    return (
      <div className="TableContainer">
        {this.props.children}
        <table>
          <thead>
            <tr className="headerRow">
              { this.props.tableInfo.map((header, i) => (
                <th key={"header" + i} className={header.headerClasses || ""} onClick={this.props.onHeaderClick ? this.props.onHeaderClick.bind(this, header.header) : undefined}>
                  { this.props.sort === header.header && this.props.sortDirection === "ASC" &&
                    <span>
                      {header.header} &darr;
                    </span>
                  }
                  { this.props.sort === header.header && this.props.sortDirection === "DESC" &&
                    <span>
                      {header.header} &uarr;
                    </span>
                  }
                  { this.props.sort !== header.header &&
                    <span>
                      {header.header}
                    </span>
                  }
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          { this.props.data.map((row, i) => (
            <tr key={"row_" + i + "_" + row.id} onClick={ this.props.onClick !== undefined ? this.props.onClick.bind(this, this.props.onClickKey === null ? row : row[this.props.onClickKey || "display_id"]) : undefined}>
              { this.props.tableInfo.map((h, j) => (
                <td key={"data" + j} className={h.classes || ""}>
                  { h.header === "Status" &&
                    <span>
                      <span className={"tdStatus" + row[h.mapping]}>
                        { /*["Requested", "Pending", "Unpaid"].includes(row[h.mapping]) &&
                          <span className="statusBull">&bull;</span>
                        */}
                        {row[h.mapping]}
                      </span>
                    </span>
                  }
                  { h.header === "Amount" &&
                    <span>
                      {"$" + Helpers.formatNumberDecimals(row[h.mapping], 2)}
                    </span>
                  }
                  { h.header !== "Status" && h.header !== "Amount" &&
                    <span>
                      { h.format &&
                        h.format(row[h.mapping])
                      }
                      { !h.format &&
                        row[h.mapping]
                      }
                    </span>
                  }
                </td>
              ))}
            </tr>
          ))}
          </tbody>
        </table>
        { this.props.data.length === 0 &&
          <div className="emptyTable">
            {this.props.noData}
          </div>
        }
      </div>
    )
  }
}

export class TableFilter extends React.Component {

  render() {
    return (
      <div className={"Filter" + (this.props.filter === this.props.currentFilter ? " FilterActive" : "")} onClick={this.props.onClick.bind(this, this.props.filter)}>
        { this.props.filter === this.props.currentFilter &&
          <span>&bull;</span>
        }
        { this.props.name }
      </div>
    )
  }
}

export class PageIndicator extends React.Component {

  render() {

    let buttons = []
    //first page
    if (this.props.page > 0) {
      buttons.push({
        index: 0
      })
      if (this.props.page > 2) {
        buttons.push({
          index: "..."
        })
      }
    }
    //-1
    if (this.props.page > 1) {
      buttons.push({
        index: this.props.page - 1
      })
    }
    //selected
    buttons.push({
      index: this.props.page
    })
    //+1
    if (this.props.page < this.props.pageMax) {
      buttons.push({
        index: this.props.page + 1
      })
    }
    //last page
    if (this.props.page < this.props.pageMax - 1) {
      if (this.props.page < this.props.pageMax - 2) {
        buttons.push({
          index: "..."
        })
      }
      buttons.push({
        index: this.props.pageMax
      })
    }

    return (
      <div className="PageIndicator">
        { (this.props.pageMax === 0 || this.props.alwaysShowCount === true) &&
          <div className="PageIndicatorCount">
            {Helpers.formatNumber(this.props.count)} Results
          </div>
        }
        { this.props.pageMax > 0 && buttons.map((button, i) => (
          <div key={"pi_" + i + "_" + button.index}
            className={button.index !== "..." ? (button.index === this.props.page ? "PageIndicatorButton PageIndicatorButtonSelected" : "PageIndicatorButton") : "PageIndicatorDots"}
            onClick={button.index !== "..." ? this.props.pageSelected.bind(this, button.index) : undefined}
            >
            { button.index === "..." &&
              <span>
                ...
              </span>
            }
            { button.index !== "..." &&
              <span>
                {button.index + 1}
              </span>
            }
          </div>
        ))}
      </div>
    )
  }
}

//The topbar for most of the site.
//Supply the active prop to set the active topbar item (Instructors, JoinTheTeam, ...)
export class TopBar extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      expanded: false
    }

    autoBind(this)
  }

  toggleMenu() {
    this.setState((old) => {
      return {
        expanded: !old.expanded
      }
    })
  }

  changeView(newView) {
    this.setState({
      expanded: false
    }, () => {
      this.props.changeView(newView)
    })
  }

  render() {
    //console.log(this.props.active)
    return (
      <div className="TopBar">
        <div className="TopBarContent">
          <div className="TopBarLogo" onClick={() => {this.changeView("Home")}}>
            <DualImage image={ImageSKBlack} />
          </div>
          <div className="TopBarLinks">
            { this.props.userInfo.user &&
              <div className={"TopBarLink TopBarProfile"} onClick={() => {this.changeView("Profile")}}>
                { this.props.userInfo.user && this.props.userInfo.user.profilePicture && this.props.userInfo.user.profilePicture.length > 0 &&
                  <DualImage image={this.props.userInfo.user.profilePicture} />
                }
                <div className="TopBarProfileInfo">
                  <div className="TopBarProfileName">
                    { this.props.userInfo.user.name }
                  </div>
                  <div className="TopBarProfileAccountType">
                    <span>
                      Profile
                    </span>
                  </div>
                </div>
              </div>
            }
            { !this.props.userInfo.user &&
              <div className={"TopBarLink TopBarLogin"} onClick={() => {this.changeView("Login")}}>
                Login
              </div>
            }
          </div>
        </div>
        <div className={"TopBarMiniContent " + (this.state.expanded ? "TopBarMiniContentExpanded" : "")}>
          <div className="TopBarMiniContentTop">
            <div className={"TopBarMenu " + (this.state.expanded ? "TopBarMenuExpanded" : "")} onClick={this.toggleMenu}>
              <div className={"menu-btn " + (this.state.expanded ? "open" : "")}>
                <div className="menu-btn__burger"></div>
              </div>
            </div>
            <div className="TopBarLogo" onClick={() => {this.changeView("Home")}}>
              <DualImage image={ImageSKWhite} />
            </div>
            <div className="TopBarSpace">
              &nbsp;
            </div>
          </div>
          { this.state.expanded &&
            <div className="TopBarMiniContentContent">
              <hr/>
              { this.props.userInfo.user &&
                <div className="TopBarMiniContentLink" onClick={() => {this.changeView("Profile")}}>
                  Profile
                </div>
              }
              { !this.props.userInfo.user &&
                <div className="TopBarMiniContentLink" onClick={() => {this.changeView("Login")}}>
                  Login
                </div>
              }
            </div>
          }
        </div>
      </div>
    )
  }
}

//The Footer that is at the bottom of each page on the site.
//Contains links throughout the page.
export class Footer extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
    }
    autoBind(this)
  }

  openEmail() {
    window.location.href = "mailto:james@simplekicking.com"
  }

  openLink(link) {
    let win = window.open(link, '_blank');
    win.focus();
  }

  render() {
    return (
      <div className="Footer">
        <div className="FooterContent">
          {/* Logo Section */}
          <div className="FooterContentSection">
            <DualImage image={ImageSKBlack} className="FooterLogo" />
          </div>
          {/* Say */}
          <div className="FooterContentSection">
            <div className="FooterTitle">
              Say
            </div>
            <div className="FooterLink" onClick={this.openEmail}>
              james@simplekicking.com
            </div>
          </div>
          {/* Account */}
          <div className="FooterContentSection">
            <div className="FooterTitle">
              Account
            </div>
            <div className="FooterLink" onClick={() => {this.props.changeView("Login")}}>
              Login
            </div>
            <div className="FooterLink" onClick={() => {this.props.changeView("Profile")}}>
              Profile
            </div>
          </div>
        </div>
        <hr className="FooterLine" />
        <div className="FooterBottom">
          <div className="copyright">
            © {(new Date()).getFullYear()} Simple Kicking LLC. All rights reserved.
          </div>
          <div className="legalLinks">
            <div className="legalLink" onClick={() => {window.location.href = "https://simplekicking.com/privacy-policy/"}}>
              Privacy Policy
            </div>
            <div className="legalLink" onClick={() => {window.location.href = "https://simplekicking.com/terms-of-use/"}}>
              Terms of Use
            </div>
          </div>
        </div>
      </div>
    )
  }
}

//Changes the image on hover to the dual image
//set properties image, hoverimage, errorimage, ...
export class DualImage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      hovering: false,
      errored: false, //has an error occurred
    }
  }

  onError() {
    this.setState({
      errored: true
    })
  }

  //true or false we are hovering
  hover(h) {
    this.setState({
      hovering: h
    })
  }

  render() {

    /*if (this.props.image === false) {
      <img onMouseOver={this.hover.bind(this, true)} onMouseLeave={this.hover.bind(this, false)} {...this.props}/>
    }*/
    let src = this.props.image
    if (this.state.hovering && this.props.hoverimage) {
      src = this.props.hoverimage
    }
    if (this.state.errored && this.props.errorimage) {
      src = this.props.errorimage
    }

    const {className, style, onClick, ...remainingProps} = this.props;

    return (
      <img className={(this.props.hoverimage ? "dualImage " : "dualImageSingle ") + (this.props.className ? this.props.className : "")}
      onError={this.onError.bind(this)}
      onMouseOver={this.hover.bind(this, true)} onMouseLeave={this.hover.bind(this, false)} alt="hover over me"
      src={src} {...remainingProps}
      style={this.props.style || {}}
      onClick={this.props.onClick ? this.props.onClick : undefined}
      title={this.props.tooltip}
      />
    );
  }
}

export class SettingsToggle extends React.Component {

  onChange(e) {
    this.props.onToggle(this.props.valueKey, e.target.checked ? "1" : "0", this.props.instructor)
  }

  render() {
    return (
      <div className="SettingsToggle" style={this.props.style}>
        <Toggle
          checked={this.props.value}
          onChange={this.onChange.bind(this)}
        />
        { this.props.name &&
          <div className="SettingsToggleName">
            {this.props.name}
          </div>
        }
      </div>
    )
  }
}

export class BodyBackgroundChanger extends React.Component {

  constructor(props) {
    super(props)
    this.oldBackgroundColor = document.body.style.backgroundColor
  }

  componentDidMount() {
    document.body.style.backgroundColor = this.props.backgroundColor
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = this.oldBackgroundColor
  }

  render() {
    return (<span></span>)
  }
}

export class MaterialButton extends React.Component {

  render() {

    let bclass = ""
    switch (this.props.type) {
      case "White":
        bclass = "MaterialButtonWhite"
        break;
      case "Red":
        bclass = "MaterialButtonRed"
        break;
      case "LightRed":
        bclass = "MaterialButtonLightRed"
        break;
      case "Green":
        bclass = "MaterialButtonGreen"
        break;
      case "LightGreen":
        bclass = "MaterialButtonLightGreen"
        break;
      case "Purple":
        bclass = "MaterialButtonPurple"
        break;
      case "LightPurple":
        bclass = "MaterialButtonLightPurple"
        break;
      case "TextDark":
        bclass = "MaterialButtonTextDark"
        break;
      case "TextDarkShadow":
        bclass = "MaterialButtonTextDarkShadow"
        break;
      case "TextRed":
        bclass = "MaterialButtonTextRed"
        break;
      case "TextRedShadow":
        bclass = "MaterialButtonTextRedShadow"
        break;
      case "TextPurpleShadow":
        bclass = "MaterialButtonTextPurpleShadow"
        break;
      case "TextGreenShadow":
        bclass = "MaterialButtonTextGreenShadow"
        break;
      case "PayPal":
        bclass = "MaterialButtonPayPal"
        break;
      //ZOCHA DEFINED BUTTONS
      case "Dark":
        bclass = "MaterialButtonDark"
        break;
      case "DarkText":
        bclass = "MaterialButtonDarkText"
        break;
      case "DarkActive":
        bclass = "MaterialButtonDarkActive"
        break;
      case "Light":
        bclass = "MaterialButtonLight"
        break;
      case "LightText":
        bclass = "MaterialButtonLightText"
        break;
      case "Sidebar":
        bclass = "MaterialButtonSidebar"
        break;
      case "SidebarActive":
        bclass = "MaterialButtonSidebarActive"
        break;
      default:
        break;
    }

    let sclass = ""
    switch (this.props.size) {
      case "Full":
        sclass = "MaterialButtonFull"
        break;
      case "Large":
        sclass = "MaterialButtonLarge"
        break;
      case "Small":
        sclass = "MaterialButtonSmall"
        break;
      case "Content":
        sclass = "MaterialButtonContent"
        break;
      case "Tiny":
        sclass = "MaterialButtonTiny"
        break;
      default:
        break;
    }

    return (
      <button type="button" className={"btn MaterialButton " + bclass + " " + sclass + (this.props.shake ? " AnimationShake" : "")}
        style={this.props.style} onClick={this.props.onClick}>{this.props.children}</button>
    )
  }
}

export class FlowwButton extends React.Component {

  render() {

    let bclass = ""
    switch (this.props.type) {
      case "Dark":
        bclass = "FormBlockButton FormBlockButtonActive"
        break;
      case "DarkText":
        bclass = "FormBlockButton FormBlockButtonText"
        break;
      case "Outline":
        bclass = "FormBlockButton FormBlockButtonOutline"
        break;
      case "Gray":
        bclass = "FormBlockButton"
        break;
      case "Theme":
        bclass = "FormBlockButton FormBlockButtonTheme"
        break;
      case "Theme2":
        bclass = "FormBlockButton FormBlockButtonTheme2"
        break;
      case "Delete":
        bclass = "FormBlockButton FormBlockButtonDelete"
        break;
      case "White":
        bclass = "FormBlockButton FormBlockButtonWhite"
        break;
      default:
        break;
    }

    let sclass = ""
    switch (this.props.size) {
      case "Small":
        sclass = "FormBlockButtonSmall"
        break;
      default:
        break;
    }

    return (
      <div type="button" className={bclass + " " + sclass + " " + this.props.className + " " + (this.props.shake ? " AnimationShake" : "")}
        style={this.props.style} onClick={this.props.onClick}>
        {this.props.children}
      </div>
    )
  }
}

export const DropDown = posed.div({
  visible: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: -20,
    opacity: 0
  }
})

export class LoadingIndicator extends React.Component {

  render() {

    let style = {}
    if (this.props.top) {
      style["marginTop"] = this.props.top
    }
    if (this.props.bottom) {
      style["marginBottom"] = this.props.bottom
    }

    let classNames = "loadingio-spinner-ball-cm9vm1ej40l" //default to yellow
    if (this.props.color === "dark") {
      classNames += " loadingio-spinner-ball-cm9vm1ej40l-dark"
    } else if (this.props.color === "light") {
      classNames += " loadingio-spinner-ball-cm9vm1ej40l-light"
    }

    if (this.props.size === "medium") {
      classNames += " loadingio-spinner-ball-cm9vm1ej40l-medium"
    } else if (this.props.size === "large") {
      classNames += " loadingio-spinner-ball-cm9vm1ej40l-large"
    }

    return (
      <div className={classNames}>
        <div className="ldio-2yvf4wa6rj5">
          <div></div>
        </div>
      </div>
    )
  }
}

export class Popup extends React.Component {

  render() {
    return (
      <div className={"Popup " + (this.props.main === "Success" ? "PopupSuccess" : "")}>
        <div className="PopupContainer">
          <div className="PopupMain">{this.props.main}</div>
          <div className="PopupTitle">{this.props.title}</div>
          <div className="PopupDescription">{this.props.description}</div>
          <div className="ButtonDiv">
            <FlowwButton type="Dark" onClick={this.props.dismiss}>Ok</FlowwButton>
          </div>
        </div>
      </div>
    )
  }
}

export class Confirmation extends React.Component {

  render() {
    return (
      <div className="Popup">
        <div className="PopupContainer">
          <div className="PopupMain">{this.props.main}</div>
          <div className="PopupTitle">{this.props.title}</div>
          <div className="PopupDescription">{this.props.description}</div>
          <div className="ButtonDiv">
            <FlowwButton type="Dark" style={{marginRight:"20px", marginLeft:"20px", marginBottom:"30px"}} onClick={() => {this.props.action(true)}}>{this.props.accept}</FlowwButton>
            <br/>
            <FlowwButton type="DarkText" onClick={() => {this.props.action(false)}}>{this.props.deny}</FlowwButton>
          </div>
        </div>
      </div>
    )
  }
}

export class ConfirmPassword extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      error: false,

      view: "Home",
      data: {},
      forceCheck: false,
      shakeButton: false,
    }
    this.cancel = this.cancel.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.formChanged = this.formChanged.bind(this)
    this.shakeTheButton = this.shakeTheButton.bind(this)
    this.checkPassword = this.checkPassword.bind(this)
    this.promptTFA = this.promptTFA.bind(this)
    this.checkTFACode = this.checkTFACode.bind(this)
  }

  componentDidMount() {
    if (this.props.method === "2FA") {
      this.promptTFA()
    }
  }

  cancel() {
    this.props.action(false)
  }

  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        if (this.props.type === "Bank") {
          requiredFields = [this.props.method === "Password" ? "password" : "code", "terms"]
        } else {
          requiredFields = [this.props.method === "Password" ? "password" : "code"]
        }
        optionals = []
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        //console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        //console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          //console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          //console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          //console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "Home":
          if (this.props.method === "Password") {
            this.checkPassword(data)
          } else {
            this.checkTFACode(data)
          }
          break;
        default:
          break;
      }
    } else {
      //console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      return {
        data: d,
        forceCheck: false
      }
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
   * Checks the password for this user.
   */
  checkPassword(data) {
    //1) Verify that this is the correct password.
    this.setState({
      loading: true,
      error: false,
    }, () => {
      API.callDarwinAPI("POST", "verifyPassword", {
        password: data.password,
      }, (result) => {
        //console.log("POST /verifyPassword", result)
        if ("error" in result) {
          this.setState({
            loading: false,
            error: result.error,
          }, () => {
            this.shakeTheButton()
          })
          return
        }
        this.props.action(data.password)
      })
    })
  }

  /*
   * Prompt a Two Factor code to be sent to the user.
   */
  promptTFA() {
    this.setState({
      loading: true,
      error: false,
    }, () => {
      API.callDarwinAPI("POST", "promptMFA", {
      }, (result) => {
        console.log("POST /promptMFA", result)
        if ("error" in result) {
          this.setState({
            loading: false,
            error: result.error,
          }, () => {
            this.shakeTheButton()
          })
          return
        }
        this.setState({
          loading: false,
          error: false,
        })
      })
    })
  }

  /*
   * Checks the Two Factor Authentication code for this user.
   */
  checkTFACode(data) {
    //1) Verify that this is the correct password.
    this.setState({
      loading: true,
      error: false,
    }, () => {
      API.callDarwinAPI("POST", "checkMFA", {
        verificationCode: data.code,
      }, (result) => {
        //console.log("POST /checkMFA", result)
        if ("error" in result) {
          this.setState({
            loading: false,
            error: result.error,
          }, () => {
            this.shakeTheButton()
          })
          return
        }
        this.props.action(data.code)
      })
    })
  }

  render() {
    return (
      <div className="Popup">
        <div className="PopupContainer">
          { this.state.loading &&
            <div className="PortalCenterCenter">
              <div className="PortalCenterCenterContent">
                <LoadingIndicator color="dark" />
              </div>
            </div>
          }
          { !this.state.loading &&
            <span>
              { this.props.method === "Password" &&
                <span>
                  <div className="PopupMain">Confirm Your Password</div>
                  { this.props.type !== "Bank" &&
                    <span>
                      <div className="PopupDescription">{this.props.type}</div>
                    </span>
                  }
                  { this.props.type === "Bank" &&
                    <span>
                      <div className="PopupDescription">We'll need you to confirm your password before updating your bank account.</div>
                      {/* Checkbox that says you agree to allow us to process transactions on your behalf. */}
                      <div className="InputBreakDiv">
                        <InputBottomLine type="checkbox" name="terms" placeholder="" validation="checkbox" required="true"
                          title="Bank Agreement" description="_render_" descriptionRender={() => {
                            return (
                              <div>
                                Your bank account will be used to process payments.
                              </div>
                            )
                          }}
                          label="I agree"
                          value={this.state.data.terms ? this.state.data.terms.value : 0} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                      </div>
                    </span>
                  }
                  { this.state.error &&
                    <div className="PopupPasswordError">
                      {this.state.error}
                    </div>
                  }
                  {/* Password Input Field */}
                  <div className="InputDiv">
                    <InputBottomLine type="password" name="password" placeholder="password" validation="text" required="true"
                      title="Password" description="Your current password used to login to Zocha."
                      value={this.state.data.password ? this.state.data.password.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                  </div>
                  {/* Submission Buttons */}
                  <div className="ButtonDiv">
                    <MaterialButton type="Dark" size="Large" style={{marginRight:"20px", marginLeft:"20px", marginBottom:"30px"}} onClick={this.submitForm} shake={this.state.shakeButton}>Submit</MaterialButton>
                    <MaterialButton type="DarkText" size="Large" style={{marginRight:"20px", marginLeft:"20px", marginBottom:"30px"}} onClick={this.cancel}>Cancel</MaterialButton>
                  </div>
                </span>
              }
              { this.props.method === "2FA" &&
                <span>
                  <div className="PopupMain">Two Factor Authentication</div>
                  { this.props.type !== "Bank" &&
                    <span>
                      <div className="PopupDescription">{this.props.type}</div>
                    </span>
                  }
                  { this.props.type === "Bank" &&
                    <span>
                      <div className="PopupDescription">We'll need you to confirm your Two Factor Authentication code before updating your bank account.</div>
                      {/* Checkbox that says you agree to allow us to process transactions on your behalf. */}
                      <div className="InputBreakDiv">
                        <InputBottomLine type="checkbox" name="terms" placeholder="" validation="checkbox" required="true"
                          title="Bank Agreement" description="_render_" descriptionRender={() => {
                            return (
                              <div>
                                Your bank account will be used to process payments.
                              </div>
                            )
                          }}
                          label="I agree"
                          value={this.state.data.terms ? this.state.data.terms.value : 0} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                      </div>
                    </span>
                  }
                  { this.state.error &&
                    <div className="PopupPasswordError">
                      {this.state.error}
                    </div>
                  }
                  {/* Code Input Field */}
                  <div className="InputDiv">
                    <InputBottomLine type="text" name="code" inputType="tel" placeholder="123456" validation="digits" required="true" validateTyping={false}
                      title="Verification Code" description="The code sent to or on your Two Factor Authentication device."
                      min={6} max={6}
                      value={this.state.data.code ? this.state.data.code.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                  </div>
                  {/* Submission Buttons */}
                  <div className="ButtonDiv">
                    <MaterialButton type="Dark" size="Large" style={{marginRight:"20px", marginLeft:"20px", marginBottom:"30px"}} onClick={this.submitForm} shake={this.state.shakeButton}>Submit</MaterialButton>
                    <MaterialButton type="DarkText" size="Large" style={{marginRight:"20px", marginLeft:"20px", marginBottom:"30px"}} onClick={this.cancel}>Cancel</MaterialButton>
                  </div>
                </span>
              }
            </span>
          }
        </div>
      </div>
    )
  }
}

export class InputBottomLine extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      valid: undefined,
      validResponse: undefined,
      failMessage: "",
      address: ''
    }

    this.onKeyPress = this.onKeyPress.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onSelectChange = this.onSelectChange.bind(this)
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
    this.onDatePickerChange = this.onDatePickerChange.bind(this)
    this.onDateTimePickerChange = this.onDateTimePickerChange.bind(this)
    this.onShopHoursChange = this.onShopHoursChange.bind(this)
    this.onFileSelectorChange = this.onFileSelectorChange.bind(this)
    this.onFileUploadChange = this.onFileUploadChange.bind(this)
    this.updateValidation = this.updateValidation.bind(this)
    this.changeFilter = this.changeFilter.bind(this)
    this.googleAddressChanged = this.googleAddressChanged.bind(this)
    this.googleAddressSelected = this.googleAddressSelected.bind(this)
  }

  componentDidMount() {
    if (this.props.forceCheck) {
      this.updateValidation(this.props.value)
    }
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.forceCheck && this.props.forceCheck) || (prevProps.matchValue !== this.props.matchValue)) {
      this.updateValidation(this.props.value, this.props.forceCheck)
    }
  }

  /*
  * Used to check if the enter key has been pressed, if so then submit the form.
  */
  onKeyPress(e) {
    if (e.keyCode === 13) {
      this.props.onEnter()
    }
  }

  /*
  * Called when the value of a form has changed.
  */
  onChange(e) {
    //validate this input and update the validation state.
    let val = e.target.value ? e.target.value : ""
    //console.log(val)
    this.updateValidation(val)
  }

  onBlur(e) {
    let val = e.target.value ? e.target.value : ""
    this.updateValidation(val, true)
  }

  onSelectChange(selection) {
    //console.log("selection: ", selection)
    let val = selection.value
    this.updateValidation(val)
  }

  onCheckboxChange(e) {
    let val = (e.target.checked ? "1" : "0")
    this.updateValidation(val)
  }

  onDatePickerChange(date) {
    console.log(date)
    if (date === null) {
      this.updateValidation("")
    } else {
      let newVal =  date.getFullYear() + "-" + Helpers.padNumber(date.getMonth() + 1, 2) + "-" + Helpers.padNumber(date.getDate(), 2)
      this.updateValidation(newVal)
    }
  }

  onDateTimePickerChange(date) {
    console.log(date)
    if (date === null) {
      this.updateValidation(0)
    } else {
      let newVal = date.getTime() / 1000
      this.updateValidation(newVal)
    }
  }

  onShopHoursChange(value, textValue) {
    //console.log("shopHoursValue: ", value)
    //console.log("shopHoursTextValue: ", textValue)
    //1) Validate the shopHours.
    if (this.updateValidation(value)) {
      //2) Update the shopHoursTextValue.
      this.props.onChange(this.props.name + "Text", textValue, true)
    }
  }

  onFileSelectorChange(url) {
    //console.log("file selection changes: ", url)
    this.updateValidation(url)
  }

  onFileUploadChange(file) {
    //console.log("file selection changes: ", file)
    this.updateValidation(file)
  }

  /**
  * Checks the validation for this field.
  * If validateTyping is false, then we don't update the error state unless forceCheck is true.
  */
  updateValidation(val, forceCheck = false) {
    //console.log("Update Validation", val, (new Date()).setTime(val))
    let value = val
    let type = this.props.validation ? this.props.validation : "text"
    let req = this.props.required === "true" ? true : false
    let matchValue = this.props.matchValue ? this.props.matchValue : ""
    let min = this.props.minimum ? this.props.minimum : -1
    let max = this.props.maximum ? this.props.maximum : -1
    if (type === "phoneNumber" || type === "phoneNumber10") {
      value = value.replace(/[^0-9]/g, '')
      //console.log("value", value)
    } else if (type === "zip") {
      value = Helpers.parseZip(value)
    } else if (type === "ein") {
      value = value.replace(/[^0-9]/g, '').substring(0, 9)
    } else if (type === "positiveNumber") {
      value = value.toString()
      value = value.replace(/[^0-9]/g, '')
    } else if (type === "money") {
      //get rid of all non digits and non decimals.
      value = value.toString()
      value = value.replace(/[^0-9.]/g, '')
      //make sure there are only 2 digits after the decimal
      let pos = value.indexOf(".")
      if (pos !== -1) {
        value = value.substring(0, pos + 3)
      }
      //remove all leading zeros.
      if (value.length > 1) {
        while (value.indexOf("0") === 0) {
          value = value.substring(1)
        }
      }
      //add a leading zero if needed.
      pos = value.indexOf(".")
      if (pos === 0) {
        value = "0" + value
      }
    } else if (type === "digits") {
      value = value.toString()
      value = value.replace(/[^0-9]/g, '')
      if (max !== -1) {
        value = value.substring(0, max)
      }
    }

    let result = {}
    if (matchValue) {
      result = Validation.validateMatch(value, type, req, matchValue, min, max);
    } else if (this.props.type === "fileUpload") {
      if (val) {
        result = {
          valid: true,
          validResponse: "valid",
          failMessage: ""
        }
      } else {
        result = {
          valid: false,
          validResponse: "empty",
          failMessage: "You must choose a file!"
        }
      }
    } else {
      result = Validation.validate(value, type, req, min, max);
    }

    //check for minDate and maxDate
    if (result.valid && (type === "date" || type === "datetime")) {
      //check minDate
      let dateObj = new Date()
      if (type === "date") {
        dateObj.setFullYear(value.substring(0, 4))
        dateObj.setMonth(parseInt(value.substring(5,7)) - 1, value.substring(8,10))
      }
      if (type === "datetime") {
        //sets the date to the number of milliseconds since 1970.
        dateObj.setTime(value)
      }
      if (this.props.minDate) {
        //console.log("minDate", value, dateObj, this.props.minDate, value.substring(8,10))
        if (dateObj <= this.props.minDate) {
          result = {
            valid: false,
            validResponse: "minimum",
            failMessage: this.props.minDateFail || "Must be greater than " + this.props.minDate
          }
        }
      }
      if (this.props.maxDate) {
        //console.log("maxDate", value, this.props.maxDate)
        if (dateObj >= this.props.maxDate) {
          result = {
            valid: false,
            validResponse: "maximum",
            failMessage: this.props.maxDateFail || "Must be less than " + this.props.maxDate
          }
        }
      }
    }

    if (!forceCheck && this.props.validateTyping !== undefined && this.props.validateTyping === false) {
      //don't set a failed message while we are typing
      this.setState({
        valid: true,
        validResponse: "valid",
        failMessage: ""
      })
    } else {
      this.setState({
        valid: result.valid,
        validResponse: result.validResponse,
        failMessage: result.failMessage
      })
    }
    this.props.onChange(this.props.name, value, result.valid)
    return result.valid
  }

  changeFilter() {
    let value = this.props.value
    for (let i = 0; i < this.props.options.length; i = i + 1) {
      if (this.props.options[i].value === value) {
        if (i + 1 < this.props.options.length) {
          value = this.props.options[i + 1].value
        } else {
          value = this.props.options[0].value
        }
        break
      }
    }
    this.props.onChange(this.props.name, value, true)
  }

  /*
  Google Address was changed
  */
  googleAddressChanged(address) {
    //console.log("Changed", address)
    this.updateValidation(address)
  }

  /*
  Google Address was selected
  */
  googleAddressSelected(address) {
    //console.log("Selected", address)
    //this.googleAddressChanged(address)

    geocodeByAddress(address).then((results) => {
      if (this.props.addressSelected !== undefined) {
        this.props.addressSelected(results)
      }
    }).catch((error) => {
      console.error('Google Address Error', error)
    })
  }

  render() {
    let selectVal = null
    let selectIndex = 0
    let options = this.props.options
    if (this.props.type === "selection" || this.props.type === "filter") {
      if (options.length === 0) {
        options = [{
          value: "",
          label: "Loading ..."
        }]
        selectVal = options[0]
      }
      if (this.props.value) {
        for (let i = 0; i < this.props.options.length; i = i + 1) {
          //check to see if this is a group or not.
          if (this.props.options[i].options) {
            //this is a group
            for (let j = 0; j < this.props.options[i].options.length; j = j + 1) {
              if (this.props.value === this.props.options[i].options[j].value) {
                selectVal = this.props.options[i].options[j]
                selectIndex = i
                break
              }
            }
          } else {
            //this is a value
            if (this.props.value === this.props.options[i].value) {
              selectVal = this.props.options[i]
              selectIndex = i
              break
            }
          }
        }
      }
      if (selectVal === null) {
        selectVal = options[0]
      }
    }

    if (this.props.type === "selection" && Helpers.isMobile()) {
      let newOptions = []
      for (let i = 0; i < options.length; i = i + 1) {
        if (options[i].options) {
          for (let j = 0; j < options[i].options.length; j = j + 1) {
            newOptions.push(options[i].options[j])
          }
        } else {
          newOptions.push(options[i])
        }
      }
      options = newOptions
    }

    let value = this.props.value
    let fee = ""
    if (value !== null && value.length > 0) {
      if (this.props.validation === "phoneNumber" || this.props.validation === "phoneNumber10") {
        //parse this into a phone number with (), spaces, and dashes
        console.log("parse", value)
        value = Helpers.parsePhoneNumber(value)
      }
      if (this.props.validation === "ein") {
        value = Helpers.parseEIN(value)
      }
      if (this.props.validation === "zip") {
        value = Helpers.parseZip(value)
      }
      if (this.props.subtype === "ssn4") {
        if (value.length > 0) {
          value = "XXX-XX-" + value
        }
      } else if (this.props.subtype === "ssn") {
        value = Helpers.formatSSN(value)
      }
      if (this.props.validation === "positiveNumber" && this.props.format !== false) {
        //parse this into a readable number
        value = Helpers.formatNumber(value)
      } else if (this.props.validation === "money") {
        //calculate the fee
        fee = this.props.feePercent * parseFloat(value)
        fee = Math.min(this.props.feeMaximum, fee)
        fee = Math.max(this.props.feeMinimum, fee)
        fee = fee.toString()

        //add in commas to the number before the decimal
        let pos = value.indexOf(".")
        //value = "$"
        if (pos === -1) {
          value = "$" + Helpers.formatNumber(value)
        } else {
          value = "$" + Helpers.formatNumber(value.substring(0, pos)) + "." + value.substring(pos + 1)
        }
        //console.log("value", value)

        //format the fee
        let posFee = fee.indexOf(".")
        if (posFee === -1) {
          fee = "$" + Helpers.formatNumberDecimals(fee, 2)
        } else {
          fee = "$" + Helpers.formatNumberDecimals(fee.substring(0, posFee) + "." + fee.substring(posFee + 1, posFee + 3), 2)
        }
        if (this.state.valid === false) {
          fee = ""
        }
      }
    }

    let checkedVal = ((this.props.value === 1 || this.props.value === "1") ? true : false )

    let dateValue = new Date()
    if (this.props.type === "date") {
      if (this.props.value !== null && value.length > 0) {
        //Parse the date value into day, month, year components
        let parts = value.split("-")
        dateValue.setFullYear(parts[0], parts[1] - 1, parts[2]) //set year, month, day
      }
      if (this.props.maxDate !== null && this.props.maxDate !== undefined) {
        if (this.props.maxDate < dateValue) {
          dateValue = this.props.maxDate
        }
      }
      if (this.props.value === null || value.length === 0) {
        dateValue = null
      }
    }
    if (this.props.type === "datetime") {
      if (this.props.value !== null && value > 0) {
        //set the number of seconds since 1970.
        dateValue.setTime(value * 1000)
        //console.log("set time")
      }
      if (this.props.maxDate !== null && this.props.maxDate !== undefined) {
        if (this.props.maxDate < dateValue) {
          dateValue = this.props.maxDate
          //console.log("max date")
        }
      }
      //console.log("Date Value", dateValue, value)
    }

    let inputStyle = this.props.style
    if (this.props.center) {
      inputStyle.textAlign = "center"
    }

    let extraInputProps = {}
    if (this.props.inputType === "number") {
      extraInputProps["step"] = "any"
    }
    if ((this.props.type === "date" || this.props.type === "datetime") && Helpers.isMobile()) {
      extraInputProps["min"] = this.props.minDate || undefined
      extraInputProps["max"] = this.props.maxDate || undefined
    }

    let multiLinePlaceholder = this.props.placeholder.replace(/\\n/g, '\n')

    if (this.props.type === "filter") {
      return (
        <div className={"inputFilter " + (selectIndex > 0 ? "inputFilterChanged" : "")}>
          <div className="inputFilterBox" onClick={this.changeFilter}>
            { selectVal.label }
          </div>
        </div>
      )
    } else {
      return (
        <div className={"inputBottomLineLightContainer " + (this.state.valid === false ? "inputBottomLineLightContainerInvalid " : (this.state.valid === true ? "inputBottomLineLightContainerValid " : "")) + (this.props.className ? this.props.className : "")}>
          { this.props.title &&
            <div className={"inputBottomLineTitle " + (this.props.value.length > 0 ? "inputBottomLineTitleActive" : "")}>
              { this.props.required !== "true" &&
                <span>
                  { this.props.title + "" /* was + " (optional)" */}
                </span>
              }
              { this.props.required === "true" &&
                <span>
                  { this.props.title }
                </span>
              }
            </div>
          }
          { this.props.description &&
            <div className="inputBottomLineDescription">
              { this.props.description === "_render_" &&
                this.props.descriptionRender()
              }
              { this.props.description !== "_render_" &&
                this.props.description
              }
            </div>
          }
          { this.props.type === "selection" && !Helpers.isMobile() &&
            <div className="inputBottomLineLightFlexBox inputBottomLineLightFlexBoxClear">
              <Select
                tabIndex={this.props.tabIndex || ""}
                name={this.props.name}
                value={selectVal}
                onChange={this.onSelectChange}
                options={options}
                clearable={false}
                className="react-select-container"
                classNamePrefix="react-select"
              />
              <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
            </div>
          }
          { this.props.type === "selection" && Helpers.isMobile() &&
            <div className="inputBottomLineLightFlexBox">
              <select
                tabIndex={this.props.tabIndex || ""}
                name={this.props.name}
                value={this.props.value}
                onChange={this.onChange}
                className="manualSelect">
                { options.map((opt, i) => (
                  <option key={"opt_" + i} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </select>
              <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
              <div>
              </div>
            </div>
          }
          { this.props.type === "checkbox" &&
            <div className={"inputBottomLineCheckbox " + (this.props.theme ? "inputBottomLineCheckbox" + this.props.theme : "")}>
              <label className={checkedVal ? "inputBottomLineCheckboxTrue" : "inputBottomLineCheckboxFalse"}>
                <input type="checkbox"
                  tabIndex={this.props.tabIndex || ""}
                  name={this.props.name}
                  checked={checkedVal}
                  onChange={this.onCheckboxChange}
                />
                { checkedVal &&
                  <DualImage image={ImageCheckmarkLine} />
                }
              </label>
              <span>{this.props.label}</span>
            </div>
          }
          { this.props.type === "shopHours" &&
            <ShopHours
              tabIndex={this.props.tabIndex || ""}
              name={this.props.name}
              value={value}
              onChange={this.onShopHoursChange}
            />
          }
          { this.props.type === "file" &&
            <FileSelectorDiv
              tabIndex={this.props.tabIndex || ""}
              name={this.props.name}
              value={value}
              onChange={this.onFileSelectorChange}
              APIName={this.props.APIName}
              showPopup={this.props.showPopup}
              maxSize={this.props.maxSize}
            />
          }
          { this.props.type === "fileUpload" &&
            <FileUploadDiv
              tabIndex={this.props.tabIndex || ""}
              name={this.props.name}
              onChange={this.onFileUploadChange}
              maxSize={this.props.maxSize}
              accept={this.props.accept}
            />
          }
          { this.props.type === "date" && !Helpers.isMobile() &&
            <div className="inputBottomLineLightFlexBox">
              <DatePicker
                dateFormat="M/d/yyyy"
                selected={dateValue}
                onChange={this.onDatePickerChange}
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                minDate={this.props.minDate || undefined}
                maxDate={this.props.maxDate || undefined}
                placeholderText={this.props.placeholder || "7/20/1969"}
                //customInput={<DatePickerButton dateSet={this.props.value !== null && this.props.value.length > 0} showError={this.state.failMessage.length > 0} />}
              />
              <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
            </div>
          }
          { this.props.type === "date" && Helpers.isMobile() &&
            <div className="inputBottomLineMobileDate">
              <input tabIndex={this.props.tabIndex ? this.props.tabIndex : ""} type={this.props.inputType ? this.props.inputType : (this.props.type ? this.props.type : "text")}
                name={this.props.name} className="inputBottomLineLight" placeholder={this.props.placeholder} value={value}
                style={inputStyle} {...extraInputProps}
                onKeyDown={this.onKeyPress} onChange={this.onChange} onBlur={this.onBlur} />
              <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
            </div>
          }
          { this.props.type === "datetime" &&
            <div className="inputBottomLineLightFlexBox">
              <DatePicker
                dateFormat="MMM d, h:mm a"
                selected={dateValue}
                onChange={this.onDateTimePickerChange}
                showYearDropdown
                showMonthDropdown
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                dropdownMode="select"
                minDate={this.props.minDate || undefined}
                maxDate={this.props.maxDate || undefined}
                placeholderText={this.props.placeholder || "7/20/1969"}
              />
              <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
            </div>
          }
          { (this.props.type === "text" || this.props.type === "password") &&
            <div className="inputBottomLineLightFlexBox">
              { this.props.type === "money" &&
                <div className="moneyInputSign">
                  $
                </div>
              }
              <input tabIndex={this.props.tabIndex ? this.props.tabIndex : ""} type={this.props.inputType ? this.props.inputType : (this.props.type ? this.props.type : "text")}
                name={this.props.name} className="inputBottomLineLight" placeholder={this.props.placeholder} value={value}
                style={inputStyle} {...extraInputProps}
                onKeyDown={this.onKeyPress} onChange={this.onChange} onBlur={this.onBlur} />
              <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
            </div>
          }
          { this.props.type === "textarea" &&
            <div className="inputBottomLineLightFlexBox">
              { this.props.type === "money" &&
                <div className="moneyInputSign">
                  $
                </div>
              }
              <ExpandingTextArea tabIndex={this.props.tabIndex ? this.props.tabIndex : ""} name={this.props.name} className="inputBottomAreaLight" placeholder={multiLinePlaceholder} value={value}
                style={inputStyle} {...extraInputProps}
                onChange={this.onChange} onBlur={this.onBlur} />
              <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
            </div>
          }
          { this.props.type === "googleAddress" &&
              <PlacesAutocomplete value={this.props.value} onChange={this.googleAddressChanged} onSelect={this.googleAddressSelected} searchOptions={{
                componentRestrictions: {
                  country: "us"
                },
                types: ['address']
              }}>
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <div className="inputBottomLineLightFlexBox">
                      <input
                        {...getInputProps({
                          placeholder: this.props.placeholder,
                          className: 'inputBottomLineLight',
                        })}
                      />
                      <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.state.failMessage.length > 0 ? "" : "inputBottomLineIconHidden")} />
                    </div>
                    <div className="GoogleMapsDropdownContainer">
                      { loading &&
                        <div className="GoogleMapsDropdownContainerLoading">Loading...</div>
                      }
                      { suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'GoogleMapsItemActive'
                          : 'GoogleMapsItem';
                        // inline style for demonstration purpose
                        const style = {}
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
          }
          { this.props.type === "display" &&
            <input readOnly type={this.props.type ? this.props.type : "display"}
              name={this.props.name} className="inputBottomLineLight inputBottomLineLightDisplay" placeholder={this.props.placeholder} value={value}
              style={inputStyle} />
          }
          { this.props.note &&
            <div className="inputBottomLineNote">
              {this.props.note}
            </div>
          }
          { this.props.calculateFee && fee.length > 0 &&
            <div className="inputBottomLineFee">
              Zocha Fee {fee}
            </div>
          }
          <div className="inputBottomLineInvalid">
            { this.state.failMessage }
          </div>
          <div style={{clear: "both"}}></div>
          {/* <div className="inputBottomLineBlockLight"></div> */}
        </div>
      )
    }
  }
}

// A text area that expands to the height of the content.
// the width of the text area doesn't change.
// Provide an inputChanged(newVal) prop as well as a 'value' prop.
// Optionals: 'placeholder', 'className'
export class ExpandingTextArea extends React.Component {

  constructor(props) {
    super(props)

    this.state = {}

    this.textArea = React.createRef()
  }

  componentDidMount() {
    let area = this.textArea.current
    area.style["overflow-y"] = "hidden"
    area.style.height = area.scrollHeight + "px";
    //area.setAttribute("style", 'height:' + area.scrollHeight + "px;overflow-y:hidden;")
  }

  onInput(e) {
    //const newVal = this.textArea.current.value
    this.props.onChange(e)
    let area = this.textArea.current
    area.style.height = "auto";
    area.style.height = area.scrollHeight + "px";
  }

  render() {
    return (
      <textarea ref={this.textArea} className={this.props.className} style={this.props.style} onChange={this.onInput.bind(this)} onInput={this.onInput.bind(this)} onBlur={this.props.onBlur.bind(this)} placeholder={this.props.placeholder} value={this.props.value}/>
    );
  }

}

export class DatePickerButton extends React.Component {

  render() {
    const {
      onClick,
      value,
      dateSet
    } = this.props

    return (
      <button type="button" className={"btn MaterialButton MaterialButtonDate " + (dateSet ? "" : "MaterialButtonDateUnset")} onClick={onClick}>
        <span>
          {value}
        </span>
        <DualImage image={ImageX} className={"inputBottomLineIcon " + (this.props.showError ? "" : "inputBottomLineIconHidden")} />
      </button>
    )
  }
}

export class FileUploadDiv extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      file: false
    }

    this.fileSelected = this.fileSelected.bind(this)

    this.filePicker = React.createRef();
  }

  fileSelected() {
    console.log("file selected")
    if (this.filePicker.current.files.length === 0) {
      //no file selected
      return
    }
    let file = this.filePicker.current.files[0]
    //file has been selected
    //console.log("file selected", file)

    //make sure th file is less than maxSize
    if (file.size > this.props.maxSize) {
      //unset the file
      this.filePicker.current.value = ""
      this.setState({
        file: false
      }, () => {
        this.props.onChange(false)
      })
      return
    }
    this.setState({
      file: file
    }, () => {
      this.props.onChange(file)
    })
    return
  }

  render() {

    let name = "Select a File"
    if (this.state.file) {
      name = this.state.file.name
      if (name.length > 23) {
        name = name.substring(0, 20) + "..."
      }
    }

    return (
      <div className="FileSelectorDiv">
        <input id={"FileSelectorInput" + this.props.name} className="FileSelectorUploadInput" type="file" ref={this.filePicker} onChange={this.fileSelected} accept={this.props.accept || "image/png, image/jpeg, image/jpg, application/pdf"} />
        <MaterialButton type="Light" size="Full" style={{padding:"0px"}}>
          <label htmlFor={"FileSelectorInput" + this.props.name} className="FileSelectorUploadInputLabel">
            {name}
          </label>
        </MaterialButton>
      </div>
    )
  }
}

export class FileSelectorDiv extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      overlay: false,
      imgError: false,
    }

    this.goToSelectFile = this.goToSelectFile.bind(this)
    this.selectedFile = this.selectedFile.bind(this)
    this.imageLoadError = this.imageLoadError.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        imgError: false
      })
    }
  }

  goToSelectFile() {
    this.setState({
      overlay: true
    })
  }

  selectedFile(file) {
    this.setState({
      overlay: false
    })
    if (file.length === 0) {
      //we didn't select anything
      return
    }
    //set this as the newly selected file
    this.props.onChange(file)
  }

  imageLoadError() {
    //console.log("image load error")
    this.setState({
      imgError: true
    })
  }

  render() {

    let value = this.props.value
    if (value === null || value.length === 0 || this.state.imgError) {
      value = ImageZochaWhite
    }

    let fullScreenFileSelectorProps = {
      finished: this.selectedFile,
      APIName: this.props.APIName,
      showPopup: this.props.showPopup,
      maxSize: this.props.maxSize,
    }

    return (
      <div className="FileSelectorDiv">
        {/*<img className="FileSelectorDivImage" src={value} onError={this.imageLoadError} alt="Selected Logo" />*/}
        <FlowwButton type="Theme" onClick={this.goToSelectFile}>
          Change Image
        </FlowwButton>
        { this.state.overlay &&
          <FullScreenFileSelector {...fullScreenFileSelectorProps} />
        }
      </div>
    )
  }
}

export class FullScreenFileSelector extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      files: [],
      uploadingFile: false,
    }

    this.loadFiles = this.loadFiles.bind(this)
    this.cancel = this.cancel.bind(this)
    this.fileSelected = this.fileSelected.bind(this)

    this.filePicker = React.createRef();
  }

  componentDidMount() {
    //Load from the API
    this.loadFiles()
  }

  loadFiles() {
    this.setState({
      loading: true
    }, () => {
      API.callDarwinAPI("GET", this.props.APIName, {}, (result) => {
        if ("error" in result) {
          console.error(result)
          this.props.showPopup("Error", "Couldn't load your files", result.error)
          return
        }
        this.setState({
          files: result.data,
          loading: false
        })
      })
    })
  }

  /*
  Deletes the selected file
  */
  deleteFile(file) {
    console.log("deleteFile", this.state.files)
    console.log("deleteFile", file)
    //1) Delete the file.
    API.callDarwinAPI("DELETE", this.props.APIName, {
      key: file.key
    }, (result) => {
      if ("error" in result) {
        this.props.showPopup("Error", "Couldn't delete your file", result.error)
        return
      }
    })
    //2) Remove the file from the list of files.
    this.setState((old) => {
      let newFiles = old.files
      newFiles = newFiles.filter(ff => ff.url !== file.url)
      return {
        files: newFiles
      }
    })
  }

  cancel() {
    this.props.finished("")
  }

  fileSelected() {
    if (this.filePicker.current.files.length === 0) {
      //no file selected
      return
    }
    let file = this.filePicker.current.files[0]

    this.setState({
      uploadingFile: true
    }, () => {
      //1) Read the File
      let reader = new FileReader();
      reader.onload = (e) => {
        let img = document.createElement("img");
        img.onload = () => {
          //2) Wait for the file to load then resize the image
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          let MAX_WIDTH = this.props.maxSize;
          let MAX_HEIGHT = this.props.maxSize;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          //3) Convert the image to a blob
          canvas.toBlob((blob) => {

            //4) Call the API to retrieve signed post parameters
            API.callDarwinAPI("POST", this.props.APIName, {}, (result) => {
              if ("error" in result) {
                this.props.showPopup("Error", "Couldn't load your files", result.error)
                return
              }
              //5) Upload the file.
              let formInputs = result.data.formInputs
              formInputs["Content-Type"] = "image/png"
              formInputs["Cache-Control"] = "max-age=2592000"
              API.uploadFileToS3(result.data.formAttributes, result.data.formInputs, result.data.filePath, blob, (result) => {
                //console.log("file upload result", result)
                if (typeof result !== 'object' || "error" in result) {
                  //5) If error, show a popup to the user and stop loading.
                  this.setState({
                    uploadingFile: false
                  })
                  this.props.showPopup("Error", "Couldn't upload your file", result)
                  return
                } else {
                  //6) Cancel, go back, and reload the images.
                  this.setState({
                    uploadingFile: false
                  }, () => {
                    this.loadFiles()
                  })
                }
              })
            })
          }, "image/png")
        }
        img.src = e.target.result;
      }
      reader.readAsDataURL(file);
    })
  }

  render() {
    return (
      <div className="FullScreenFileSelector">
        { this.state.uploadingFile &&
          <div className="FullScreenFileSelectorFileLoading">
            <LoadingIndicator color="dark" />
          </div>
        }
        { !this.state.uploadingFile &&
          <span>
            <div className="FullScreenFileSelectorTitle">File Selector</div>
            <div className="FullScreenFileSelectorDescription">Select an image or upload a new image using the plus button below.</div>
            <div className="FullScreenFileSelectorFileTypes">We accept png and jpg/jpeg files.</div>
            <div className="FullScreenFileSelectorFileTypes">1920 x 1080 recommended</div>
            <div className="ButtonDiv">
              <FlowwButton type="Dark" onClick={this.cancel}>
                Cancel
              </FlowwButton>
            </div>
            <div className="FullScreenFileSelectorContainer">
              { this.state.loading &&
                <div className="FullScreenFileSelectorFileLoading">
                  <LoadingIndicator color="dark" top="68px" />
                </div>
              }
              { !this.state.loading &&
                <div className="FullScreenFileSelectorFiles">
                  <div className="FullScreenFileSelectorFileUpload">
                    <input id="FullScreenFile" className="FullScreenFileUploaderInput" type="file" ref={this.filePicker} onChange={this.fileSelected} accept="image/png, image/jpeg" />
                    <label htmlFor="FullScreenFile">
                      <img src={ImageAddPhoto} alt="Upload" />
                    </label>
                  </div>
                  { !this.state.loading && this.state.files.map((file, i) => (
                    <div key={"file" + i} className="FullScreenFileSelectorFile">
                      <DualImage image={ImageXButton} className="FullScreenFileSelectorFileX" onClick={this.deleteFile.bind(this, file)} />
                      <img src={file.url} className="FullScreenFileSelectorFileImage" alt={"Icon " + i} onClick={() => {this.props.finished(file.url)}} />
                    </div>
                  ))}
                </div>
              }
            </div>
          </span>
        }
      </div>
    )
  }
}

export class ShopHours extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
    }

    this.parseTimeslots = this.parseTimeslots.bind(this)
    this.padNumber = this.padNumber.bind(this)
    this.onChange = this.onChange.bind(this)
    this.dayToAbbreviation = this.dayToAbbreviation.bind(this)
  }

  //Parses the timeslots out of the days and returns it as an array of days
  parseTimeslots(value) {
    let days = []
    let values = []
    if (value.length > 0) {
      values = value.split(",")
    }
    for (let i = 0; i < 7; i = i + 1) {
      let startTime = i * 24
      let stopTime = (i + 1) * 24

      let foundRange = "0" //0 is closed.
      //find any ranges that cover this timerange
      for (let r = 0; r < values.length; r = r + 1) {
        let times = values[r].split("-")
        let rangeStartTime = parseFloat(times[0].replace(":", "."))
        let rangeStartHour = parseInt(times[0].split(":")[0])
        let rangeStartMinute = parseInt(times[0].split(":")[1])

        let rangeStopTime = parseFloat(times[1].replace(":", "."))
        let rangeStopHour = parseInt(times[1].split(":")[0])
        let rangeStopMinute = parseInt(times[1].split(":")[1])

        let inRange = false
        if (startTime >= rangeStartTime && startTime < rangeStopTime) {
          //console.log("start time of day " + i + " is in range: " + rangeStartTime + " - " + rangeStopTime)
          inRange = true
        } else if (stopTime <= rangeStopTime && stopTime > rangeStartTime) {
          //console.log("stop time of day " + i + " is in range: " + rangeStartTime + " - " + rangeStopTime)
          inRange = true
        } else if (startTime < rangeStartTime && stopTime > rangeStopTime) {
          //console.log("day " + i + " contains range: " + rangeStartTime + " - " + rangeStopTime)
          inRange = true
        }

        if (inRange) {
          //we found the range for this day, now calculate the start and end time on a 0-24 hour schedule
          let stHour = 0
          let stMinute = 0
          let endHour = 24
          let endMinute = 0

          if (rangeStartTime > startTime) {
            stHour = rangeStartHour - (i * 24)
            stMinute = rangeStartMinute
          }
          if (rangeStopTime < stopTime) {
            endHour = rangeStopHour - (i * 24)
            endMinute = rangeStopMinute
          }

          if (stHour === 0 && stMinute === 0 && endHour === 24 && endMinute === 0) {
            //Open All Day
            foundRange = "1"
          } else {
            foundRange = this.padNumber(stHour, 2) + ":" + this.padNumber(stMinute, 2) + "-" + this.padNumber(endHour, 2) + ":" + this.padNumber(endMinute, 2)
          }
          break;
        }
      }
      days.push(foundRange)
    }
    return days
  }

  /*
  Pads a number with leading zeros.
  */
  padNumber(number, digits = 2) {
    let pad = ""
    for (let i = 0; i < digits; i = i + 1) {
      pad += "0"
    }
    let dmax = Math.max(digits, ("" + number).length)
    return (pad + number).slice(-dmax)
  }

  onChange(day, time1, time2) {
    //1) First parse into timeslots.
    let timeslots = this.parseTimeslots(this.props.value)
    //2) Update the day that was edited.
    let newValue = time1 + "-" + time2
    if (time1 === "0" || time1 === "1") {
      newValue = time1
    }
    timeslots[day] = newValue
    //3) Combine the timeslots.
    let combined = ""
    let textGroups = {}
    for (let i = 0; i < timeslots.length; i = i + 1) {
      let addString = ""
      if (timeslots[i] === "0") {
        //nothing to do as this is a closed date.
        if ("0" in textGroups) {
          textGroups["0"].push(i)
        } else {
          textGroups["0"] = [i]
        }
        continue;
      } else if (timeslots[i] === "1") {
        let startHour = 24 * i
        let endHour = 24 * (i + 1)
        addString = this.padNumber(startHour, 2) + ":00-" + this.padNumber(endHour, 2) + ":00"

        if ("1" in textGroups) {
          textGroups["1"].push(i)
        } else {
          textGroups["1"] = [i]
        }
      } else {
        let times = timeslots[i].split("-")
        let rangeStartHour = parseInt(times[0].split(":")[0])
        let rangeStartMinute = parseInt(times[0].split(":")[1])
        let rangeStopHour = parseInt(times[1].split(":")[0])
        let rangeStopMinute = parseInt(times[1].split(":")[1])

        let textRangeStartHour = rangeStartHour % 12
        if (textRangeStartHour === 0) {
          textRangeStartHour = 12
        }
        let textRangeStopHour = rangeStopHour % 12
        if (textRangeStopHour === 0) {
          textRangeStopHour = 12
        }

        let textString = this.padNumber(textRangeStartHour, 1) + ":" + this.padNumber(rangeStartMinute, 2) + "-" + this.padNumber(textRangeStopHour, 1) + ":" + this.padNumber(rangeStopMinute, 2)

        rangeStartHour += 24 * i
        rangeStopHour += 24 * i

        addString = this.padNumber(rangeStartHour, 2) + ":" + this.padNumber(rangeStartMinute, 2) + "-" + this.padNumber(rangeStopHour, 2) + ":" + this.padNumber(rangeStopMinute, 2)

        if (textString in textGroups) {
          textGroups[textString].push(i)
        } else {
          textGroups[textString] = [i]
        }
      }
      if (combined.length > 0) {
        combined += ","
      }
      combined += addString
    }

    //4) Parse the textGroups into a textString
    let textValue = ""
    let available = [0, 1, 2, 3, 4, 5, 6]
    while (available.length > 0) {
      let search = available[0]
      for (let key in textGroups) {
        if (textGroups[key].includes(search)) {
          //found the search.
          let days = textGroups[key]
          let last = -1
          let groupStart = -1
          let groupEnd = -1
          for (let i = 0; i < days.length; i = i + 1) {
            if (groupStart === -1) {
              //new group
              groupStart = days[i]
              groupEnd = days[i]
              last = days[i]
            } else if (days[i] === last + 1) {
              //extend the range
              groupEnd = days[i]
              last = days[i]
            } else {
              //range is over add it to the textValue, then reset.
              if (textValue.length > 0) {
                textValue += ", "
              }
              textValue += this.dayToAbbreviation(groupStart)
              if (groupEnd !== groupStart) {
                textValue += "-" + this.dayToAbbreviation(groupEnd)
              }

              groupStart = days[i]
              groupEnd = days[i]
              last = days[i]
            }
            available = available.filter((value, index, arr) => {
              return value !== days[i]
            })
          }
          //add in the range
          if (textValue.length > 0) {
            textValue += ", "
          }
          textValue += this.dayToAbbreviation(groupStart)
          if (groupEnd !== groupStart) {
            textValue += "-" + this.dayToAbbreviation(groupEnd)
          }
          //add in the text
          if (key === "0") {
            textValue += " Closed"
          } else if (key === "1") {
            textValue += " All Day"
          } else {
            textValue += " " + key
          }
          break
        }
      }
    }
    //5) Push the changes up to the next level.
    this.props.onChange(combined, textValue)
  }

  /*
  The day of the week, 0-6 where 0 is Monday.
  Converts to abbreviations like M, T, W, Th, F, Sa, Su
  */
  dayToAbbreviation(day) {
    switch (day) {
      case 0:
        return "M"
      case 1:
        return "Tu"
      case 2:
        return "W"
      case 3:
        return "Th"
      case 4:
        return "F"
      case 5:
        return "Sa"
      case 6:
        return "Su"
      default:
        return "?"
    }
  }

  render() {
    let timeslots = this.parseTimeslots(this.props.value)
    let hours = [
      {title:"Monday", day:0, value:timeslots[0]},
      {title:"Tuesday", day:1, value:timeslots[1]},
      {title:"Wednesday", day:2, value:timeslots[2]},
      {title:"Thursday", day:3, value:timeslots[3]},
      {title:"Friday", day:4, value:timeslots[4]},
      {title:"Saturday", day:5, value:timeslots[5]},
      {title:"Sunday", day:6, value:timeslots[6]},
    ]
    return (
      <div className="ShopHours">
        {hours.map((hour, i) => (
          <ShopHour key={"ShopHour" + i}
            {...hour}
            onChange={this.onChange}
          />
        ))}
      </div>
    )
  }
}

export class ShopHour extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange(selection, action) {
    //get the old props.
    let startTime = this.props.value.split("-")[0]
    let endTime = this.props.value.split("-")[1]

    if (selection.value === "0" || selection.value === "1") {
      //Closed or All Day selected so send this as the value
      startTime = selection.value
      endTime = selection.value
    } else {
      if (action.name === "startTime") {
        startTime = selection.value
      } else {
        endTime = selection.value
      }
    }
    if (startTime === undefined) {
      startTime = "01:00"
    }
    if (endTime === undefined) {
      endTime = "23:00"
    }
    this.props.onChange(this.props.day, startTime, endTime)
  }

  render() {
    let fullOptions = [
      { value:"0", label:"Closed" },
      { value:"1", label:"All Day" },

      { value:"00:00", label:"Midnight" },
      { value:"00:15", label:"12:15 am" },
      { value:"00:30", label:"12:30 am" },
      { value:"00:45", label:"12:45 am" },

      { value:"01:00", label:"01:00 am" },
      { value:"01:15", label:"01:15 am" },
      { value:"01:30", label:"01:30 am" },
      { value:"01:45", label:"01:45 am" },

      { value:"02:00", label:"02:00 am" },
      { value:"02:15", label:"02:15 am" },
      { value:"02:30", label:"02:30 am" },
      { value:"02:45", label:"02:45 am" },

      { value:"03:00", label:"03:00 am" },
      { value:"03:15", label:"03:15 am" },
      { value:"03:30", label:"03:30 am" },
      { value:"03:45", label:"03:45 am" },

      { value:"04:00", label:"04:00 am" },
      { value:"04:15", label:"04:15 am" },
      { value:"04:30", label:"04:30 am" },
      { value:"04:45", label:"04:45 am" },

      { value:"05:00", label:"05:00 am" },
      { value:"05:15", label:"05:15 am" },
      { value:"05:30", label:"05:30 am" },
      { value:"05:45", label:"05:45 am" },

      { value:"06:00", label:"06:00 am" },
      { value:"06:15", label:"06:15 am" },
      { value:"06:30", label:"06:30 am" },
      { value:"06:45", label:"06:45 am" },

      { value:"07:00", label:"07:00 am" },
      { value:"07:15", label:"07:15 am" },
      { value:"07:30", label:"07:30 am" },
      { value:"07:45", label:"07:45 am" },

      { value:"08:00", label:"08:00 am" },
      { value:"08:15", label:"08:15 am" },
      { value:"08:30", label:"08:30 am" },
      { value:"08:45", label:"08:45 am" },

      { value:"09:00", label:"09:00 am" },
      { value:"09:15", label:"09:15 am" },
      { value:"09:30", label:"09:30 am" },
      { value:"09:45", label:"09:45 am" },

      { value:"10:00", label:"10:00 am" },
      { value:"10:15", label:"10:15 am" },
      { value:"10:30", label:"10:30 am" },
      { value:"10:45", label:"10:45 am" },

      { value:"11:00", label:"11:00 am" },
      { value:"11:15", label:"11:15 am" },
      { value:"11:30", label:"11:30 am" },
      { value:"11:45", label:"11:45 am" },

      { value:"12:00", label:"Noon" },
      { value:"12:15", label:"12:15 pm" },
      { value:"12:30", label:"12:30 pm" },
      { value:"12:45", label:"12:45 pm" },

      { value:"13:00", label:"01:00 pm" },
      { value:"13:15", label:"01:15 pm" },
      { value:"13:30", label:"01:30 pm" },
      { value:"13:45", label:"01:45 pm" },

      { value:"14:00", label:"02:00 pm" },
      { value:"14:15", label:"02:15 pm" },
      { value:"14:30", label:"02:30 pm" },
      { value:"14:45", label:"02:45 pm" },

      { value:"15:00", label:"03:00 pm" },
      { value:"15:15", label:"03:15 pm" },
      { value:"15:30", label:"03:30 pm" },
      { value:"15:45", label:"03:45 pm" },

      { value:"16:00", label:"04:00 pm" },
      { value:"16:15", label:"04:15 pm" },
      { value:"16:30", label:"04:30 pm" },
      { value:"16:45", label:"04:45 pm" },

      { value:"17:00", label:"05:00 pm" },
      { value:"17:15", label:"05:15 pm" },
      { value:"17:30", label:"05:30 pm" },
      { value:"17:45", label:"05:45 pm" },

      { value:"18:00", label:"06:00 pm" },
      { value:"18:15", label:"06:15 pm" },
      { value:"18:30", label:"06:30 pm" },
      { value:"18:45", label:"06:45 pm" },

      { value:"19:00", label:"07:00 pm" },
      { value:"19:15", label:"07:15 pm" },
      { value:"19:30", label:"07:30 pm" },
      { value:"19:45", label:"07:45 pm" },

      { value:"20:00", label:"08:00 pm" },
      { value:"20:15", label:"08:15 pm" },
      { value:"20:30", label:"08:30 pm" },
      { value:"20:45", label:"08:45 pm" },

      { value:"21:00", label:"09:00 pm" },
      { value:"21:15", label:"09:15 pm" },
      { value:"21:30", label:"09:30 pm" },
      { value:"21:45", label:"09:45 pm" },

      { value:"22:00", label:"10:00 pm" },
      { value:"22:15", label:"10:15 pm" },
      { value:"22:30", label:"10:30 pm" },
      { value:"22:45", label:"10:45 pm" },

      { value:"23:00", label:"11:00 pm" },
      { value:"23:15", label:"11:15 pm" },
      { value:"23:30", label:"11:30 pm" },
      { value:"23:45", label:"11:45 pm" },

      { value:"24:00", label:"Midnight" },
    ]

    //Find the value in the fullOptions given this.props.value
    let startValue = []
    let endValue = []
    let startOptions = fullOptions
    let endOptions = fullOptions
    let endDisabled = false
    if (this.props.value === "0") {
      startValue = fullOptions[0]
      endValue = fullOptions[0]
      endDisabled = true
    } else if (this.props.value === "1") {
      startValue = fullOptions[1]
      endValue = fullOptions[1]
      endDisabled = true
    } else {
      //we have a time to parse
      let startTime = this.props.value.split("-")[0]
      let endTime = this.props.value.split("-")[1]
      //now find the time
      for (let v = 0; v < fullOptions.length; v = v + 1) {
        if (fullOptions[v].value === startTime) {
          startValue = fullOptions[v]
        }
        if (fullOptions[v].value === endTime) {
          endValue = fullOptions[v]
        }
      }
    }

    return (
      <div className="ShopHour">
        {/* Show the day of the week */}
        <div className="ShopHourDay">{this.props.title}</div>
        {/* Show the first select option */}
        <Select
          name="startTime"
          options={startOptions}
          value={startValue}
          onChange={this.onChange}
          className="react-select-container ShopHourSelect"
          classNamePrefix="react-select"
        />
        {/* Show the dash */}
        <div className="ShopHourDash">–</div>
        {/* Show the second select option */}
        <Select
          name="stopTime"
          options={endOptions}
          value={endValue}
          onChange={this.onChange}
          isDisabled={endDisabled}
          className="react-select-container ShopHourSelect"
          classNamePrefix="react-select"
        />
      </div>
    )
  }
}

export class Statistic extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    let imgSrc = Helpers.getStatImage(this.props.type)
    let helpDescription = Helpers.getStatHelpDescription(this.props.type)

    return (
      <div className="Statistic" onClick={this.props.expand}>
        <div className="StatisticHelp">
          <DualImage image={ImageHelpIcon} />
          <div className="StatisticHelpPopup">
            <div className="StatisticHelpPopupTitle">
              {this.props.type}
            </div>
            <div className="StatisticHelpPopupDescription">
              {helpDescription}
            </div>
          </div>
        </div>
        <div>
          <img src={imgSrc} alt={this.props.type} className="StatisticImage" />
        </div>
        <div className="StatisticValue">
          {this.props.value}
        </div>
        <div className="StatisticType">
          {this.props.type}
        </div>
        <div className="StatisticDuration">
          {this.props.duration}
        </div>
      </div>
    )
  }
}

export class StatisticsGraph extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      data: [],
      newData: [],
      showing: ["Views", "Taps", "Favorites", "Uses"],
      hoverData: {},
      daysToShow: 7, //defaults to a week
    }

    this.loadData = this.loadData.bind(this)
    this.dataReturned = this.dataReturned.bind(this)
    this.calculateDataToShow = this.calculateDataToShow.bind(this)
    this.toggleType = this.toggleType.bind(this)
    this.updateTopData = this.updateTopData.bind(this)
    this.changeTimescale = this.changeTimescale.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    this.props.loadData(this.dataReturned, this.state.daysToShow)
  }

  dataReturned(data) {
    let dd = data
    for (let i = 0; i < dd.length; i = i + 1) {
      let total = 0;
      for (let j = 0; j < dd[i].data.length; j = j + 1) {
        total += parseInt(dd[i].data[j].y)
      }
      dd[i].total = total
    }

    this.setState({
      data: dd,
      hoverData: {
      }
    }, () => {
      this.calculateDataToShow()
    })
  }

  calculateDataToShow() {
    this.setState((state) => {
      let newData = []
      for (let i = 0; i < state.data.length; i = i + 1) {
        if (state.showing.includes(state.data[i].id)) {
          newData.push(state.data[i])
        }
      }

      //find the totals.
      let totals = {}
      for (let i = 0; i < newData.length; i = i + 1) {
        totals[newData[i].id] = newData[i].total
      }

      return {
        newData: newData,
        hoverData: {
          Views: totals["Views"],
          Taps: totals["Taps"],
          Favorites: totals["Favorites"],
          Uses: totals["Uses"]
        },
      }
    })
  }

  /*
  Toggles showing a type of data.
  */
  toggleType(type) {
    this.setState((state) => {
      let showing = state.showing
      if (showing.includes(type)) {
        //remove the type
        showing = showing.filter(t => t !== type)
      } else {
        showing.push(type)
      }
      return {
        showing: showing,
        hoverData: {},
      }
    }, () => {
      this.calculateDataToShow()
    })
  }

  /*
  Updates the values for the top view when hovering over the data.
  */
  updateTopData(slice) {

    this.setState((state) => {
      let hd = {}
      let old = state.hoverData
      let changed = false

      let newData = state.newData
      let totals = {}
      for (let i = 0; i < newData.length; i = i + 1) {
        totals[newData[i].id] = newData[i].total
      }

      for (let i = 0; i < slice.points.length; i = i + 1) {
        let p = slice.points[i]
        hd[p["serieId"]] = p["data"]["y"] + " / " + totals[p["serieId"]]
        if (old[p["serieId"]] !== hd[p["serieId"]]) {
          changed = true
        }
      }
      hd["date"] = slice.points[0]["data"]["xFormatted"]
      if (old["date"] !== hd["date"]) {
        changed = true
      }
      if (Object.keys(old).length !== Object.keys(hd).length) {
        changed = true
      }

      if (changed) {
        //console.log("setting data", hd)
        return {
          hoverData: hd
        }
      }
      return {}
    })
  }

  /*
  Changes the timescale
  */
  changeTimescale() {
    this.setState((state) => {
      let dts = state.daysToShow
      if (dts === 7) {
        dts = 14;
      } else if (dts === 14) {
        dts = 30;
      } else if (dts === 30) {
        dts = 90;
      } else {
        dts = 7;
      }

      //find the totals.
      let newData = state.newData
      let totals = {}
      for (let i = 0; i < newData.length; i = i + 1) {
        totals[newData[i].id] = newData[i].total
      }

      return {
        daysToShow: dts,
        hoverData: {},
      }
    }, () => {
      this.loadData()
    })
  }

  render() {
    return (
      <div className="StatisticsGraph">
        <div className="StatisticsGraphTop">
          <DualImage image={ImageXButton} className="StatisticsGraphXButton" onClick={this.props.close} />
          {/* Date */}
          <div className="StatisticsGraphSelector">
            <div className="StatisticsGraphSelectorTitle" onClick={this.changeTimescale}>
              { this.state.daysToShow === 7 &&
                <span>
                  Last Week
                </span>
              }
              { this.state.daysToShow === 14 &&
                <span>
                  Last 14 Days
                </span>
              }
              { this.state.daysToShow === 30 &&
                <span>
                  Last Month
                </span>
              }
              { this.state.daysToShow === 90 &&
                <span>
                  Last 3 Months
                </span>
              }
            </div>
            <div className="StatisticsGraphSelectorValue" style={{paddingTop:"20px"}}>
              {this.state.hoverData.date !== undefined ? this.state.hoverData.date : "–"}
            </div>
          </div>
          {/* Views */}
          <div className="StatisticsGraphSelector">
            <div className={"StatisticsGraphSelectorButton " + (this.state.showing.includes("Views") ? "StatisticsGraphSelectorButtonSelected" : "")} onClick={this.toggleType.bind(this, "Views")}>
              <img src={Helpers.getStatImage("Views")} alt="Views" className="StatisticsGraphSelectorButtonImage1" />
              <span className="StatisticsGraphSelectorButtonText">Views</span>

              <div className="StatisticHelp">
                <img src={ImageHelpIcon} alt="Help" className="StatisticsGraphSelectorButtonImage2" />
                <div className="StatisticHelpPopup">
                  <div className="StatisticHelpPopupTitle">
                    Views
                  </div>
                  <div className="StatisticHelpPopupDescription">
                    {Helpers.getStatHelpDescription("Views")}
                  </div>
                </div>
              </div>
            </div>
            <div className="StatisticsGraphSelectorValue">
              {this.state.hoverData.Views !== undefined ? this.state.hoverData.Views : "–"}
            </div>
          </div>
          {/* Taps */}
          <div className="StatisticsGraphSelector">
            <div className={"StatisticsGraphSelectorButton StatisticsGraphSelectorButtonTaps " + (this.state.showing.includes("Taps") ? "StatisticsGraphSelectorButtonSelected" : "")} onClick={this.toggleType.bind(this, "Taps")}>
              <img src={Helpers.getStatImage("Taps")} alt="Taps" className="StatisticsGraphSelectorButtonImage1" />
              <span className="StatisticsGraphSelectorButtonText">Taps</span>

              <div className="StatisticHelp">
                <img src={ImageHelpIcon} alt="Help" className="StatisticsGraphSelectorButtonImage2" />
                <div className="StatisticHelpPopup">
                  <div className="StatisticHelpPopupTitle">
                    Taps
                  </div>
                  <div className="StatisticHelpPopupDescription">
                    {Helpers.getStatHelpDescription("Taps")}
                  </div>
                </div>
              </div>
            </div>
            <div className="StatisticsGraphSelectorValue">
              {this.state.hoverData.Taps !== undefined ? this.state.hoverData.Taps : "–"}
            </div>
          </div>
          {/* Favorites */}
          <div className="StatisticsGraphSelector">
            <div className={"StatisticsGraphSelectorButton StatisticsGraphSelectorButtonFavorites " + (this.state.showing.includes("Favorites") ? "StatisticsGraphSelectorButtonSelected" : "")} onClick={this.toggleType.bind(this, "Favorites")}>
              <img src={Helpers.getStatImage("Favorites")} alt="Favorites" className="StatisticsGraphSelectorButtonImage1" />
              <span className="StatisticsGraphSelectorButtonText">Favorites</span>

              <div className="StatisticHelp">
                <img src={ImageHelpIcon} alt="Help" className="StatisticsGraphSelectorButtonImage2" />
                <div className="StatisticHelpPopup">
                  <div className="StatisticHelpPopupTitle">
                    Favorites
                  </div>
                  <div className="StatisticHelpPopupDescription">
                    {Helpers.getStatHelpDescription("Favorites")}
                  </div>
                </div>
              </div>
            </div>
            <div className="StatisticsGraphSelectorValue">
              {this.state.hoverData.Favorites !== undefined ? this.state.hoverData.Favorites : "–"}
            </div>
          </div>
          {/* Uses */}
          <div className="StatisticsGraphSelector">
            <div className={"StatisticsGraphSelectorButton StatisticsGraphSelectorButtonUses " + (this.state.showing.includes("Uses") ? "StatisticsGraphSelectorButtonSelected" : "")} onClick={this.toggleType.bind(this, "Uses")}>
              <img src={Helpers.getStatImage("Uses")} alt="Uses" className="StatisticsGraphSelectorButtonImage1" />
              <span className="StatisticsGraphSelectorButtonText">Uses</span>

              <div className="StatisticHelp">
                <img src={ImageHelpIcon} alt="Help" className="StatisticsGraphSelectorButtonImage2" />
                <div className="StatisticHelpPopup">
                  <div className="StatisticHelpPopupTitle">
                    Uses
                  </div>
                  <div className="StatisticHelpPopupDescription">
                    {Helpers.getStatHelpDescription("Uses")}
                  </div>
                </div>
              </div>
            </div>
            <div className="StatisticsGraphSelectorValue">
              {this.state.hoverData.Uses !== undefined ? this.state.hoverData.Uses : "–"}
            </div>
          </div>
        </div>
        <div className="StatisticsGraphGraph">
          <ResponsiveLine
            data={this.state.newData}
            margin={{ top: 50, right: 50, bottom: 100, left: 50 }}
            xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'millisecond' }}
            xFormat="time:%Y-%m-%d"
            //xScale={{ type: 'point' }}
            yScale={{ type: 'linear', stacked: false, min: 'auto', max: 'auto' }}
            curve="monotoneX"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              format: '%b %d',
              tickValues: 'every day',
              tickSize: 10,
              tickPadding: 10,
              tickRotation: -90,
              legend: '',
              legendOffset: 0,
              legendPosition: 'middle'
            }}
            /*axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -90,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle'
            }}*/
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: -40,
                legendPosition: 'middle',
                format: value =>
                  Helpers.formatNumberOnlyInts(value)
            }}
            enableGridX={false}
            enableGridY={false}
            //colors="#393c51"
            lineWidth={4}
            pointSize={6}
            pointColor="#393c51"
            pointLabel="y"
            pointLabelYOffset={-12}
            enableArea={true}
            areaBlendMode="darken"
            areaOpacity={0.05}
            enableSlices="x"
            legends={[]}
            keys={['Views', 'Taps', 'Favorites', 'Uses']}
            colors={(data) => {
              return data.color
            }}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 14,
                    fontFamily: 'inherit',
                    fill: "#B3B1B5"
                  }
                },
              },
              crosshair: {
                line: {
                  strokeWidth: 1,
                  stroke: '#393c51',
                  strokeOpacity: 1,
                  strokeDasharray: 0,
                },
              },
            }}
            sliceTooltip={({ slice }) => {
              this.updateTopData(slice)
              return (
                <span></span>
                /*<div
                  style={{
                    background: 'white',
                    padding: '9px 12px',
                    border: '1px solid #bbcc00',
                  }}
                >
                  <div>x: {slice.id}</div>
                  {slice.points.map(point => (
                    <div
                      key={point.id}
                      style={{
                        color: point.serieColor,
                        padding: '3px 0',
                      }}
                    >
                      <strong>{point.serieId}</strong> [{point.data.yFormatted}]
                    </div>
                  ))}
                </div>*/
              );
            }}
          />
        </div>
      </div>
    )
  }
}

export class AdminDiv extends React.Component {

  render() {
    return (
      <div className="AdminDiv" onClick={this.props.onClick}>
        <div className="AdminDivImage">
          <img src={this.props.image} alt={this.props.title} />
        </div>
        <div className="AdminDivContainer">
          <div className="AdminDivTitle" style={{color:this.props.color}}>
            {this.props.title}
          </div>
          <div className="AdminDivDescription">
            {this.props.description}
          </div>
        </div>
      </div>
    )
  }
}

export class TabSelection extends React.Component {

  render() {
    return (
      <div className="TabSelection">
        { this.props.tabs.map((tab, i) => (
          <span key={i}>
            <div className={"Tab " + (this.props.selected === tab ? "TabSelected" : "")} onClick={this.props.changeTab.bind(this, tab)}>
              {tab}
            </div>
            { i !== (this.props.tabs.length - 1) &&
              <div className="TabSpacer">
              </div>
            }
          </span>
        ))}
      </div>
    )
  }
}

export class CopyBox extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      copied: false
    }

    this.copyData = this.copyData.bind(this)
  }

  copyData() {
    copy(this.props.copyText)
    this.setState({
      copied: true
    })
    console.log(window.location)
  }

  render() {
    return (
      <div className="CopyBox" onClick={this.copyData}>
        <div className="CopyBoxText">
          {this.props.children}
        </div>
        <div className={"CopyBoxResult " + (this.state.copied ? "CopyBoxResultCopied" : "")}>
          {this.state.copied ? "Copied" : "Copy"}
        </div>
      </div>
    )
  }
}

export class CopyBox2 extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      copied: false
    }

    this.copyData = this.copyData.bind(this)
  }

  copyData() {
    copy(this.props.copyText)
    this.setState({
      copied: true
    }, () => {
      setTimeout(() => {
        this.setState({
          copied: false
        })
      }, 4000)
    })
    console.log(window.location)
  }

  render() {
    return (
      <div className="CopyBox2" onClick={this.copyData}>
        <div className="CopyBox2Text">
          { this.state.copied ? "Copied" : "Get shareable link"}
        </div>
        <DualImage image={ImageShareLink} style={{height:"28px"}} />
      </div>
    )
  }
}

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap defaultZoom={7} defaultCenter={props.location}
    defaultOptions={{ styles:GoogleMapsStyle }} >
    {props.isMarkerShown === true && <Marker position={props.location} />}
  </GoogleMap>
))

export class GoogleMapPoint extends React.Component {
  state = {
    isMarkerShown: false,
  }

  componentDidMount() {
    this.delayedShowMarker()
  }

  delayedShowMarker = () => {
    setTimeout(() => {
      this.setState({ isMarkerShown: true })
    }, 3000)
  }

  handleMarkerClick = () => {
    this.setState({ isMarkerShown: false })
    this.delayedShowMarker()
  }

  render() {
    return (
      <MyMapComponent
        isMarkerShown={true}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAyirBcC1J8lstrGk3t3p_Z8P_wqeXjxxg&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={
          <div className="GoogleMapsDiv GoogleMapsLoading" style={{height: this.props.height || "200px"}} />
        }
        containerElement={<div className="GoogleMapsDiv GoogleMapsContainer" style={{height: this.props.height || "200px"}} />}
        mapElement={<div className="GoogleMapsDiv GoogleMapsMap" style={{height: this.props.height || "200px"}} />}
        location={{lat: this.props.latitude ? parseFloat(this.props.latitude) : -34.567, lng: this.props.longitude ? parseFloat(this.props.longitude) : 150.644}}
      />
    )
  }
}

export class PlaidLinkContainer extends React.Component {

  constructor(props) {
    super(props)

    this.handleOnEvent = this.handleOnEvent.bind(this)
    this.handleOnExit = this.handleOnExit.bind(this)
    this.handleOnLoad = this.handleOnLoad.bind(this)
    this.handleOnSuccess = this.handleOnSuccess.bind(this)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleOnEvent(eventName, metadata) {
    //console.log("Plaid Event", eventName, metadata)
  }

  handleOnExit(err, metadata) {
    console.log("Plaid Exit")
    if (this.props.failed) {
      this.props.failed(err, metadata)
    }
  }

  handleOnLoad() {
    //console.log("Plaid Loaded")
  }

  handleOnSuccess(publicToken, metadata) {
    //console.log("Plaid Success")
    this.props.submit(publicToken, metadata)
  }

  render() {
    let classes = "btn MaterialButton"
    if (this.props.theme === "dark") {
      classes += " MaterialButtonDark"
    } else {
      classes += " MaterialButtonLight"
    }
    classes += " MaterialButtonLarge"

    return (
      <PlaidLink
        clientName="Zocha"
        countryCodes={["US"]}
        env={this.props.userInfo.user.test_account === "1" ? "sandbox" : "production"}
        publicKey="87e11632ed4807aa85ca51327d5eff"
        product={["auth"]}
        webhook={API.baseURL() + "plaidhook"}
        language="en"
        token={this.props.token}
        user={{legalName:this.props.userInfo.user.name, emailAddress:this.props.userInfo.user.email}}
        onEvent={this.handleOnEvent}
        onExit={this.handleOnExit}
        onLoad={this.handleOnLoad}
        onSuccess={this.handleOnSuccess}
        className={classes}
        style={{}}
        shouldShowPlaid={this.props.shouldShowPlaid}
        >
        {this.props.children}
      </PlaidLink>
    )
  }
}

export class PlaidLink extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      disabledButton: true,
      linkLoaded: false,
      initializeURL: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
    }

    this.onScriptError = this.onScriptError.bind(this);
    this.onScriptLoaded = this.onScriptLoaded.bind(this);
    this.handleLinkOnLoad = this.handleLinkOnLoad.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.initializePlaid = this.initializePlaid.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.token !== this.props.token && prevProps.token === "loading") {
      //we have loaded the token.
      if (!this.state.disabledButton) {
        this.initializePlaid()
      }
    }
  }

  onScriptError() {
    console.error("There was an issue loading the link-initialize.js script")
  }

  onScriptLoaded() {
    this.setState({
      disabledButton: false
    });
    if (this.props.token !== "loading") {
      this.initializePlaid()
    }
  }

  initializePlaid() {
    let data = {
      apiVersion: this.props.apiVersion,
      clientName: this.props.clientName,
      countryCodes: this.props.countryCodes,
      language: this.props.language,
      env: this.props.env,
      key: this.props.publicKey,
      onEvent: this.props.onEvent,
      onExit: this.props.onExit,
      onLoad: this.handleLinkOnLoad,
      onSuccess: this.props.onSuccess,
      product: this.props.product,
      selectAccount: this.props.selectAccount,
      token: this.props.token,
      userLegalName: this.props.user.legalName,
      userEmailAddress: this.props.user.emailAddress,
      webhook: this.props.webhook,
    }
    //console.log(data)
    this.linkHandler = window.Plaid.create(data);
  }

  handleLinkOnLoad() {
    if (this.props.onLoad != null) {
      this.props.onLoad()
    }
    this.setState({
      linkLoaded: true
    })
  }

  handleOnClick(event) {
    if (this.props.onClick != null) {
      this.props.onClick(event)
    }
    if (this.props.shouldShowPlaid) {
      //We need to prompt to show Plaid
      this.props.shouldShowPlaid((show) => {
        if (show) {
          if (this.linkHandler) {
            this.linkHandler.open()
          }
        }
      })
    } else {
      //Show Plaid if we can
      if (this.linkHandler) {
        this.linkHandler.open()
      }
    }
  }

  exit(configurationObject) {
    if (this.linkHandler) {
      this.linkHandler.exit(configurationObject);
    }
  }

  render() {
    return (
      <div>
        <button
          type="button"
          onClick={this.handleOnClick}
          disabled={this.state.disabledButton || this.props.token === "loading"}
          style={this.props.style}
          className={this.props.className}>
          {this.props.children}
        </button>
        <Script
          url={this.state.initializeURL}
          onError={this.onScriptError}
          onLoad={this.onScriptLoaded} />
      </div>
    )
  }
}

export class LinkBankView extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,

      data: {},
      bankToken: "loading"
    }

    this.shouldShowPlaid = this.shouldShowPlaid.bind(this)
    this.linkBankAccount = this.linkBankAccount.bind(this)
    this.loadBankToken = this.loadBankToken.bind(this)
    this.editBankAccount = this.editBankAccount.bind(this)
    this.editBankAccountFailed = this.editBankAccountFailed.bind(this)
  }

  componentDidMount() {
    let bankAccounts = []
    if (this.props.userInfo && this.props.userInfo.BankAccounts && this.props.userInfo.BankAccounts._embedded && this.props.userInfo.BankAccounts._embedded["funding-sources"]) {
      bankAccounts = this.props.userInfo.BankAccounts._embedded["funding-sources"]
    }
    if (Helpers.bankAccountEditing(bankAccounts)) {
      this.loadBankToken()
    }
  }

  /*
  Called by PlaidLinkContainer to determine if we should show the Plaid Link.
  First prompt the user to enter in their password.
  */
  shouldShowPlaid(callback) {
    let bankAccounts = []
    if (this.props.userInfo && this.props.userInfo.BankAccounts && this.props.userInfo.BankAccounts._embedded && this.props.userInfo.BankAccounts._embedded["funding-sources"]) {
      bankAccounts = this.props.userInfo.BankAccounts._embedded["funding-sources"]
    }

    if (this.props.userInfo.user.can_link_bank === "1") {
      callback(true)
    } else if (Helpers.bankAccountEditing(bankAccounts)) {
      callback(true)
    } else {
      callback(false)
      //show a prompt
      this.props.showPopup("Issue", "Please contact support to change your bank account.", "To prevent fraud, we'll need to make sure this is a valid request to update your bank information. Please contact support@zocha.com to link your bank account.")
    }
    /*this.props.showConfirmPassword((password) => {
      if (password === false) {
        callback(false)
      } else {
        let data = {
          password: {
            value: password,
            valid: true
          }
        }
        this.setState({
          data: data
        }, () => {
          callback(true)
        })
      }
    }, "We'll need you to confirm your password before updating your bank account.")*/
  }

  /*
  Submits the data retreived from Plaid Link to store the bank credentials with Plaid/Zocha/Dwolla.
  */
  linkBankAccount(publicToken, metadata) {
    //console.log(publicToken, metadata)
    this.setState({
      loading: true
    }, () => {
      if (this.props.loadingCallback !== undefined) {
        this.props.loadingCallback(true)
      }
      let data = {
        publicToken: publicToken,
        accountID: metadata.account.id,
        accountName: metadata.account.name,
        accountMask: metadata.account.mask,
        accountType: metadata.account.type,
        accountSubtype: metadata.account.subtype,
        verificationStatus: metadata.account.verification_status || "verified",
        //password: this.state.data.password.value, //for verifying that the user's password is correct.
      }
      if (metadata.institution.name) {
        data.institutionName = metadata.institution.name
      }
      if (metadata.institution.institution_id) {
        data.institutionID = metadata.institution.institution_id
      }
      //console.log(data)
      API.callDarwinAPI("POST", "bankAccount", data, (result) => {
        //console.log("POST /bankAccount", result)
        if ("error" in result) {
          this.setState({
            loading: false
          }, () => {
            if (this.props.loadingCallback !== undefined) {
              this.props.loadingCallback(false)
            }
            if (result.error === "Approval Required") {
              this.props.showPopup("Issue", "Please contact support to change your bank account.", "To prevent fraud, we'll need to make sure this is a valid request to update your bank information. Please contact support@zocha.com to link your bank account.")
            } else {
              this.props.showPopup("Error", "There was an issue linking your bank account.", result.error)
            }
          })
          return
        }
        this.props.finished()
      })
    })
  }

  /*
  Loads the bank token used to edit the Bank Account.
  */
  loadBankToken() {
    API.callDarwinAPI("GET", "bankEditToken", {}, (result) => {
      //console.log("GET /bankEditToken", result)
      if ("error" in result) {
        this.props.showPopup("Error", "There was an issue verifying this bank account.", result.error)
        return
      }
      let token = result.data
      this.setState({
        bankToken: token
      })
    })
  }

  /*
  Updates the bank account in Zocha/Dwolla using the provided Plaid information.
  */
  editBankAccount(publicToken, metadata) {
    this.setState({
      loading: true
    }, () => {
      if (this.props.loadingCallback !== undefined) {
        this.props.loadingCallback(true)
      }
      let data = {
        publicToken: publicToken,
        accountID: metadata.account.id,
        accountName: metadata.account.name,
        accountMask: metadata.account.mask,
        accountType: metadata.account.type,
        accountSubtype: metadata.account.subtype,
        verificationStatus: metadata.account.verification_status || "verified",
      }
      if (metadata.institution.name) {
        data.institutionName = metadata.institution.name
      }
      if (metadata.institution.institution_id) {
        data.institutionID = metadata.institution.institution_id
      }
      //console.log(data)
      API.callDarwinAPI("PUT", "bankAccount", data, (result) => {
        //console.log("PUT /bankAccount", result)
        if ("error" in result) {
          this.setState({
            loading: false
          }, () => {
            if (this.props.loadingCallback !== undefined) {
              this.props.loadingCallback(false)
            }
            this.props.showPopup("Error", "There was an issue linking your bank account.", result.error)
          })
          return
        }
        this.props.finished()
      })
    })
  }

  /*
  Called when there is a failure on the bank account.
  */
  editBankAccountFailed(err, metadata) {
    //console.log(err, metadata)
    if (err && ["ITEM_LOCKED", "ITEM_NOT_SUPPORTED", "ITEM_NO_VERIFICATION", "TOO_MANY_VERIFICATION_ATTEMPTS"].includes(err.error_code)) {
      //we need to remove this zocha_bank_account entry
      API.callDarwinAPI("DELETE", "bankAccount", {
        reason: "failed"
      }, (result) => {
        //console.log("DELETE /bankAccount", result)
        if ("error" in result) {
          this.setState({
            loading: false
          }, () => {
            if (this.props.loadingCallback !== undefined) {
              this.props.loadingCallback(false)
            }
            this.props.showPopup("Error", "There was an issue linking your bank account.", result.error)
          })
          return
        }
        this.props.finished()
      })
    }
  }

  render() {

    let plaidProps = {
      shouldShowPlaid: this.shouldShowPlaid,
      userInfo: this.props.userInfo,
      showPopup: this.props.showPopup,
      showConfirmation: this.props.showConfirmation,
      submit: this.linkBankAccount,
      failed: this.editBankAccountFailed,
      theme: this.props.theme
    }
    let bankAccounts = []
    if (this.props.userInfo && this.props.userInfo.BankAccounts && this.props.userInfo.BankAccounts._embedded && this.props.userInfo.BankAccounts._embedded["funding-sources"]) {
      bankAccounts = this.props.userInfo.BankAccounts._embedded["funding-sources"]
    }

    if (Helpers.bankAccountEditing(bankAccounts)) {
      plaidProps.submit = this.editBankAccount
      plaidProps.token = this.state.bankToken
    }

    return (
      <div className="SContainer">
        { this.state.loading &&
          <LoadingIndicator color={this.props.theme === "dark" ? "light" : "dark"} top="50px" />
        }
        { !this.state.loading && Helpers.bankAccountEditing(bankAccounts) &&
          <span>
            <div className="SContainerTitle2">
              Verify Bank Account
            </div>
            <DualImage image={this.props.theme === "dark" ? ImageBankWhite : ImageBank} style={{height:"150px"}} />
            <div className="SContainerDescription2">
              You'll need to provide additional information to link your bank account. Click the button below to finish the process.
            </div>
            <PlaidLinkContainer {...plaidProps}>
              <DualImage image={this.props.theme === "dark" ? ImageBankWhite : ImageBank} />
              <span>
                Verify Bank Account
              </span>
            </PlaidLinkContainer>
          </span>
        }
        { !this.state.loading && Helpers.bankAccountWaiting(bankAccounts) &&
          <span>
            <div className="SContainerTitle2">
              Bank Account Processing
            </div>
            <DualImage image={this.props.theme === "dark" ? ImageBankWhite : ImageBank} style={{height:"150px"}} />
            <div className="SContainerDescription2">
              Your bank account is currently being verified.
              This could take a day or two depending on your bank.
              <br/><br/>
              Once this process is complete, you'll be able to send payment requests.
            </div>
          </span>
        }
        { !this.state.loading && !Helpers.bankAccountEditing(bankAccounts) && !Helpers.bankAccountWaiting(bankAccounts) &&
          <span>
            <div className="SContainerTitle2">
              Bank Authentication
            </div>
            <DualImage image={this.props.theme === "dark" ? ImageBankWhite : ImageBank} style={{height:"150px"}} />
            { this.props.purpose === "Request" &&
              <div className="SContainerDescription2">
                You'll need to link a bank account to send requests.
              </div>
            }
            { this.props.purpose === "Send" &&
              <div className="SContainerDescription2">
                We use Plaid (acquired by Visa) to securely authenticate bank accounts. Zocha never receives or stores your banking credentials. Find out more at <a href="https://zocha.com/security" target="_blank" rel="noopener noreferrer">zocha.com/security</a>
              </div>
            }
            <PlaidLinkContainer {...plaidProps}>
              <span>
                Continue
              </span>
            </PlaidLinkContainer>
          </span>
        }
      </div>
    )
  }
}
