import React from 'react';
import autoBind from 'react-autobind';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
// import * as Helpers from '../Helpers.js';

//import the Darwin API classes
import API from '../API.js';

import ImageProfile from '../images/Icons/TeamGray.svg'

export class Home extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,

      view: "Home",
      subview: "Home",
      teams: [],
    }
    autoBind(this)
  }

  componentDidMount() {
    this.calculateView()

    //load the teams.
    this.getTeams()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  getTeams() {
    this.setState({
      loading: true
    }, () => {
      //2) Submit the form.
      let data = {
      }
      API.callDarwinAPI("GET", "userProfile", data, (result) => {
        if ("error" in result) {
          this.setState({
            loading: false
          }, () => {
            this.props.showPopup("Error", "Couldn't load your teams.", result.error)
          })
          return
        }
        console.log(result.data)
        this.setState({
          loading: false,
          teams: result.data.teams,
        })
      })
    })
  }

  calculateView() {
  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        requiredFields = []
        optionals = []
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "Home":
          //this.updateFees(data)
          break;
        default:
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      return {
        data: d,
        forceCheck: false
      }
    }, () => {
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  /*
  Opens the Stripe subscription portal for this Team.
  */
  manageSubscription(team) {
    console.log(team)
    API.callDarwinAPI("GET", "teamBillingLink/" + team.id, {}, (result) => {
      if ("error" in result) {
        this.setState({
          loading: false
        }, () => {
          this.props.showPopup("Error", "No Access", result.error)
        })
        return
      }
      console.log(result)
      this.openLink(result.data.url)
    })
  }

  openLink(link) {
    if (link === null) {
      return
    }
    let win = window.open(link, '_blank');
    if (win !== false && win !== null) {
      win.focus();
    } else {
      document.location.href = link
    }
  }

  accessLevelDisplayName(level) {
    switch (level) {
      case "None":
        return "Free"
      case "Stripe":
        return "Free"
      default:
        return level
    }
  }

  accessLevelPercent(level) {
    switch (level) {
      case "None":
      case "Free":
      case "Stripe":
        return 0
      case "Purple":
      case "Purple Team":
      case "Purple Private Coach":
      case "Silver":
        return 33
      case "Gold":
      case "Gold Team":
      case "Gold Private Coach":
        return 67
      case "Pro":
      case "Pro Team":
      case "Pro Private Coach":
      case "GoldStats":
        return 100
      default:
        return 0
    }
  }

  render() {

    return (
      <div className="Home">
        { this.state.loading &&
          <div className="PortalCenterCenter">
            <div className="PortalCenterCenterContent">
              <Components.LoadingIndicator color="dark" />
            </div>
          </div>
        }
        { !this.state.loading &&
          <div className="HomeTopSection">
            <div className="HomeTopSectionContent">
              <div className="HomeTopSectionTitle">
                Simple Kicking
              </div>
              <div className="HomeTopSectionDescription">
                Manage Teams
              </div>
              { this.state.teams.length === 0 &&
                <div className="HomeTopSectionNoTeams">
                  <div className="HomeTopSectionNoTeamsTitle">
                    No Teams Found
                  </div>
                  <div className="HomeTopSectionNoTeamsDescription">
                    You are not a part of any teams. First open up the Simple Kicking App, tap on the 'Team' tab, and create a new team.
                  </div>
                </div>
              }
              { this.state.teams.length > 0 &&
                <div className="HomeTopSectionTeams">
                  { this.state.teams.map((team, i) => (
                    <div className="HomeTopSectionTeam" key={"team_" + i + "_" + team.id}>
                      <div className="HomeTopSectionTeamName">
                        {team.name}
                      </div>
                      <div className="HomeTopSectionTeamLevel">
                        {team.level}
                      </div>
                      <div className="HomeTopSectionTeamRow">
                        { !team.logo &&
                          <Components.DualImage image={ImageProfile} className="HomeTopSectionTeamRowEmptyImage" />
                        }
                        { team.logo &&
                          <Components.DualImage image={team.logo} className="HomeTopSectionTeamRowSetImage" />
                        }
                        { ["Admin", "Coach"].includes(team.role) && !team.access.includes("Team") && !team.access.includes("Private Coach") &&
                          <Components.FlowwButton type={"Theme"} onClick={this.manageSubscription.bind(this, team)}>
                            Manage Subscription
                          </Components.FlowwButton>
                        }
                      </div>
                      <div className="HomeTopSectionTeamAccessLevel">
                        <div className="HomeTopSectionTeamAccessLevelTitle">
                          Access Level
                        </div>
                        <div className="HomeTopSectionTeamAccessLevelValue">
                          {this.accessLevelDisplayName(team.access)}
                        </div>
                      </div>
                      <div className="HomeTopSectionTeamAccessBar">
                        <div className="HomeTopSectionTeamAccessBarBackground" />
                        <div className="HomeTopSectionTeamAccessBarForeground" style={{width: this.accessLevelPercent(team.access) + "%", background: team.access.includes("Team") ? "#461D7C" : (team.access.includes("Private Coach") ? "#000000" : "#FFCD00")}} />
                      </div>
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>
        }
      </div>
    )
  }
}
