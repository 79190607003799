import React from 'react';

//React Components
import * as Components from './resources/Components.js';
//import * as Generation from './resources/Generation.js';
import * as Helpers from './resources/Helpers.js';
import * as Home from './resources/screens/Home.js';
import * as Four0Four from './resources/screens/404.js';

import * as Login from './resources/screens/Login.js';
import * as Profile from './resources/screens/Profile.js';

//CSS
//import './resources/master.min.css';
import './resources/master.scss';

//import the Darwin API classes
import API from './resources/API.js';

/*
The Entrypoint for the Application. This manages what screen to show, user info, ...
*/
export class App extends React.Component {

  constructor(props) {
    super(props)

    let obj = Object.assign({}, window.location)
    console.log("location", obj)

    //let testData = false

    this.state = {
      view: "Home", //The view that is currently being shown "Home", "Auth".
      subview: "Home", //The subview we are viewing: "Home", "Account"
      tertiaryView: "Home", //The tertiary view we are accessing if there is one.
      fourthView: "Home", //The fourth view or piece of data if there is one.
      render: 1, //A counter to keep track of repetetive actions.
      loadingUserInfo: false, //Are we loading the user information?
      instructorInfo: false, //The instructor info
      instructorLiveClasses: [], //The live classes for this instructor.
      instructorOnDemandClasses: [], //The on demand classes for this instructor.

      popup: false, //is there a popup to show
      popupType: "", //the type of popup to show ("Error", "Success")
      popupTitle: "", //the popup title if there is one
      popupDescription: "", //the popup description if there is one

      confirmation: false, //is there a confirmation to show
      confirmationTitle: "", //the title text
      confirmationDescription: "", //the description text
      confirmationAcccept: "", //the accept text
      confirmationDeny: "", //the denial text
      confirmationAction: () => {}, //the function to call when complete

      userInfo: {}, //The user's information who we are accessing.
      adminInfo: {}, //The actual user who logged in.
      accountTakeover: false, //Has an associate or admin taken over this account?.
    }

    this.updateViewBasedOnPath = this.updateViewBasedOnPath.bind(this)
    this.changeView = this.changeView.bind(this)
    this.reloadUser = this.reloadUser.bind(this)
    this.loadUserInfo = this.loadUserInfo.bind(this)
    this.getUserInfo = this.getUserInfo.bind(this)
    this.logout = this.logout.bind(this)
    this.showPopup = this.showPopup.bind(this)
    this.dismissPopup = this.dismissPopup.bind(this)
    this.showConfirmation = this.showConfirmation.bind(this)
    this.showConfirmPassword = this.showConfirmPassword.bind(this)

    this.stopAccessing = this.stopAccessing.bind(this)
    this.accessAccount = this.accessAccount.bind(this)
    this.loggedInUser = this.loggedInUser.bind(this)
    this.firstLogin = this.firstLogin.bind(this)
    this.homeAndReloadUserInfo = this.homeAndReloadUserInfo.bind(this)

    console.log("Mode - " + (Helpers.isDevelopment() ? "Test" : "Live"))
    console.log("App Version - " + Helpers.getAppVersion())

    //turn off logs if in deployment.
    if (window.location.hostname === "www.wefloww.com" || window.location.hostname === "wefloww.com" || window.location.hostname === "demo.wefloww.com") {
      console.log = function() {}
    }
  }

  componentDidMount() {
    let shouldCheckAuth = this.updateViewBasedOnPath()

    //1) Check to see if we are logged in and if not redirect to the login screen
    if (shouldCheckAuth) {
      this.reloadUser()
    } else {
      //get the user info if we are logged in.
      if (API.hasLoggedIn()) {
        this.loadUserInfo()
      }
    }

    //2) Detect back/forward buttons.
    window.onpopstate = () => {
      this.updateViewBasedOnPath()
    }
  }

  /*
  Updates which view we are on based on the current url path.
  Returns whether or not we should reload user info and check if we are logged in.
  */
  updateViewBasedOnPath() {
    let pathComponents = Helpers.getWindowPathComponents()
    console.log("update view based on path", pathComponents)

    let shouldCheckAuth = true
    switch (pathComponents[0]) {
      case "":
      case "home":
        this.changeView("Home")
        shouldCheckAuth = true
        break;
      case "profile":
        this.changeView("Profile")
        shouldCheckAuth = true
        break;
      case "404":
        this.changeView("Four0Four")
        shouldCheckAuth = false
        break;
      //member pages - login and account, ...
      case "m": {
        let pc1 = pathComponents.length > 1 ? pathComponents[1] : ""
        switch (pc1) {
          case "login":
            this.changeView("Login")
            shouldCheckAuth = false
            break;
          default:
            this.changeView("Home")
            shouldCheckAuth = true
            break;
        }
        break;
      }
      default:
        this.changeView("Four0Four")
        shouldCheckAuth = false
        break;
    }
    return shouldCheckAuth
  }

  /*
  Call this to change and update the view.
  This will handle resetting the state for you.
  */
  changeView(newView, tertiaryView = false, fourthView = false, callback = null) {
    //console.log("changeView", newView, tertiaryView, fourthView)

    switch (newView) {
      case "Home": {
        //1) Update the window path.
        Helpers.updateWindowPath("/")
        //2) Get the user info
        this.setState({
          view: "Home",
          subview: "Home",
          tertiaryView: tertiaryView,
          render: this.state.render + 1,
        })
        break;
      }
      case "Profile": {
        //1) Update the window path.
        Helpers.updateWindowPath("/profile")
        //2) Get the user info
        this.setState({
          view: "Home",
          subview: "Profile",
          tertiaryView: tertiaryView,
          render: this.state.render + 1,
        })
        break;
      }
      case "Four0Four": {
        //1) Update the window path.
        Helpers.updateWindowPath("/404")
        //2) Redirect to the main Simple Kicking 404 Page
        document.location.href = "https://simplekicking.com/404"
        break;
      }
      case "Join": {
        //1) Update the window path.
        Helpers.updateWindowPath("/join")
        //2) Get the user info
        this.setState({
          view: "Home",
          subview: "Join",
          tertiaryView: tertiaryView,
          render: this.state.render + 1,
        })
        break
      }
      case "Login": {
        //1) Update the window path.
        Helpers.updateWindowPath("/m/login")
        //2) Get the user info
        this.setState({
          view: "Home",
          subview: "Login",
          tertiaryView: "Home",
          render: this.state.render + 1,
        })
        break
      }
      default:
        console.log("Unknown Change View:", newView, tertiaryView, fourthView)
        break;
    }
    window.scrollTo(0, 0);
    if (callback !== null && typeof callback === "function") {
      callback()
    }
  }

  /*
  Reloads this user's information and resets the accessAccountID.
  */
  reloadUser() {
    API.checkAuthentication(() => {
      //2) Load the current user's information
      this.setState({
        userInfo: {},
        adminInfo: {},
        accountTakeover: false,
        messages: []
      })
      API.accessAccountID = false
      this.loadUserInfo()
    })
  }

  /*
  * Loads the user information for the user.
  * If accessing an account, then load the info for that user.
  */
  loadUserInfo(callback = null) {
    this.setState({
      loadingUserInfo: true,
      fourthView: "Home",
    }, () => {
      API.callDarwinAPI("GET", "userProfile", {}, (result) => {
        if ("error" in result) {
          this.setState({
            loadingUserInfo: false
          }, () => {
            this.showPopup("Error", "Couldn't get the user profile", result.error)
          })
          return
        }
        console.log("User Profile", result.data)

        let data = {
          userInfo: result.data,
          loadingUserInfo: false,
        }
        if (!this.state.accountTakeover) {
          data['adminInfo'] = result.data
        }
        this.setState(data, () => {
          if (callback !== null && typeof callback === "function") {
            callback()
          }
        })
      })
    })
  }

  /*
  * Gets the user's profile without a lot of view changes, then calls the callback.
  */
  getUserInfo(callback = null) {
    API.callDarwinAPI("GET", "userProfile", {}, (result) => {
      if ("error" in result) {
        this.setState({
          loadingUserInfo: false
        }, () => {
          this.showPopup("Error", "Couldn't get the user profile", result.error)
        })
        return
      }
      console.log("User Profile", result.data)

      let data = {
        userInfo: result.data,
        loadingUserInfo: false,
      }
      if (!this.state.accountTakeover) {
        data['adminInfo'] = result.data
      }
      this.setState(data, () => {
        if (callback !== null && typeof callback === "function") {
          callback()
        }
      })
    })
  }

  /*
  * Logs the user out of the program and returns to the login page.
  */
  logout() {
    API.logout()
  }

  /*
  * Show the error with a dismiss button
  */
  showPopup(type, title, description) {
    let ds = description
    if (typeof ds === 'object') {
      //turn this into a JSON array
      try {
        ds = JSON.stringify(ds)
      } catch (e) {
        ds = "Unparseable issue"
      }
    }
    this.setState({
      popup: true,
      popupType: type,
      popupTitle: title,
      popupDescription: ds
    })
  }

  /*
  * Dismiss the error message.
  */
  dismissPopup() {
    this.setState({
      popup: false,
      popupTitle: "",
      popupDescription: "",
    })
  }

  /*
  * Shows a confirmation dialog with an optional callback passing true or false
  * to whether they have accepted the action
  */
  showConfirmation(title, description, accept, deny, action) {
    this.setState({
      confirmation: true,
      confirmationTitle: title,
      confirmationDescription: description,
      confirmationAccept: accept,
      confirmationDeny: deny,
      confirmationAction: (result) => {
        this.setState({
          confirmation: false
        })
        action(result)
      }
    })
  }

  /*
  Shows a confirm password prompt over the whole screen.
  Set method to be Password or TFA.
  */
  showConfirmPassword(action, type = "Confirm your password to perform this action.", method = "Password") {
    this.setState({
      confirmPassword: true,
      confirmPasswordType: type,
      confirmPasswordAction: (result) => {
        this.setState({
          confirmPassword: false
        })
        action(result)
      },
      confirmPasswordMethod: method,
    })
  }

  /*
  * Go back to our account.
  */
  stopAccessing(callback = null) {
    //console.log("Stop Accessing Account")
    this.setState({
      accountTakeover: false,
      userInfo: this.state.adminInfo
    }, () => {
      API.accessAccountID = false
      this.changeView("Home")
      this.loadUserInfo(callback)
    })
  }

  /*
  Starts accessing an account if possible.
  */
  accessAccount(accountID, callback = null) {
    if (!accountID) {
      console.log("Cannot access a null account.")
      return
    }
    //console.log("Accessing Account:", accountID)
    if (this.state.userInfo.user.admin === "1") {
      this.setState({
        accountTakeover: true,
        userInfo: {}
      }, () => {
        //load this user's information
        API.accessAccountID = accountID
        if (!callback) {
          //go to the home page if no callback
          this.changeView("Home")
        }
        this.loadUserInfo(callback)
      })
    } else {
      this.showPopup("Error", "You don't have the proper permissions to access this account.", "You must be a Simple Kicking Admin to experience this functionality.")
    }
  }

  /*
  A user has just logged in. We should reset and reload everything.
  */
  loggedInUser() {
    //1) Update the view.
    this.changeView("Home")
    //2) Reload the information about the logged in user to set the accountType, messages, and userInfo
    this.reloadUser()
  }

  /*
  A user has just created an account, determine if there is one outstanding request. If so, then go to that page.
  */
  firstLogin() {
    this.loggedInUser()
  }

  /*
  Returns home and reloads the user information for the current accessed user.
  */
  homeAndReloadUserInfo() {
    //1) Reload the information about the logged in user to set the accountType, messages, and userInfo
    this.loadUserInfo(() => {
      //console.log("LOADED USER INFO", this.state.userInfo.user.accountStatus)
      this.changeView("Home")
      return
      /*
      //2) Update based on the loaded user information.
      switch (this.state.userInfo.user.accountStatus) {
        case "0":
          this.changeView("CreateAccountFinished")
          break;
        //1 - a finalized User.
        case "2":
          //Instructor needs to fill out the form.
          this.changeView("CreateAccountInstructorForm")
          break;
        case "3":
          //Instructor is under review.
          this.changeView("CreateAccountInstructorReview")
          break;
        //4 - a finalized instructor.
        default:
          this.changeView("Home")
          break;
      }*/
    })
  }

  render() {

    //shown for a popup.
    let popupProps = {
      main: this.state.popupType,
      title: this.state.popupTitle,
      description: this.state.popupDescription,
      dismiss: this.dismissPopup,
    }
    //shown for a confirmation dialog.
    let confirmationProps = {
      main: "Confirmation",
      title: this.state.confirmationTitle,
      description: this.state.confirmationDescription,
      accept: this.state.confirmationAccept,
      deny: this.state.confirmationDeny,
      action: this.state.confirmationAction
    }

    let confirmPasswordProps = {
      type: this.state.confirmPasswordType,
      action: this.state.confirmPasswordAction,
      method: this.state.confirmPasswordMethod,
    }

    let screenProps = {
      userInfo: this.state.userInfo,
      adminInfo: this.state.adminInfo,
      accountTakeover: this.state.accountTakeover,
      showPopup: this.showPopup,
      showConfirmation: this.showConfirmation,
      showConfirmPassword: this.showConfirmPassword,
      takeoverAccount: this.accessAccount,
      changeView: this.changeView,
      render: this.state.render,
      tertiaryView: this.state.tertiaryView, //usually false, if not false then go to this view.
      fourthView: this.state.fourthView,
      getUserInfo: this.getUserInfo,
      homeAndReloadUserInfo: this.homeAndReloadUserInfo,
    }

    //default the props from screenProps, then set your own props
    let homeProps = Object.assign({
      //Custom props here
    }, screenProps)
    let four0fourProps = Object.assign({
      //Custom props here
    }, screenProps)
    let loginProps = Object.assign({
      //Custom props here
    }, screenProps)
    let profileProps = Object.assign({
      //Custom props here
    }, screenProps)

    let topBarProps = {
      active: this.state.subview,
      changeView: this.changeView,
      userInfo: this.state.userInfo,
      accountTakeover: this.state.accountTakeover,
      stopTakeover: this.stopAccessing,
    }
    let footerProps = {
      changeView: this.changeView,
    }

    return (
      <div className="App">
        { this.state.popup &&
          <Components.Popup {...popupProps} />
        }
        { this.state.confirmation &&
          <Components.Confirmation {...confirmationProps} />
        }
        { this.state.confirmPassword &&
          <Components.ConfirmPassword {...confirmPasswordProps} />
        }
        { this.state.view === "Home" &&
          <span className="AppContainer">
            <div className="MainBody">
              { ["Home", "Login", "Four0Four", "Profile"].includes(this.state.subview) &&
                <div className="Portal">
                  {/* We are loading the user info, show the full screen loader */}
                  { this.state.loadingUserInfo &&
                    <FullScreenLoading />
                  }
                  {/* We have loaded the user information, show the top bar */}
                  { !this.state.loadingUserInfo &&
                    <Components.TopBar {...topBarProps} />
                  }
                  {/* Home Screen */}
                  { !this.state.loadingUserInfo && this.state.subview === "Home" &&
                    <Home.Home {...homeProps} />
                  }
                  {/* Login Screen */}
                  { !this.state.loadingUserInfo && this.state.subview === "Login" &&
                    <Login.Login {...loginProps} />
                  }
                  {/* Profile Screen */}
                  { !this.state.loadingUserInfo && this.state.subview === "Profile" &&
                    <Profile.Profile {...profileProps} />
                  }
                  {/* 404 Error Screen */}
                  { !this.state.loadingUserInfo && this.state.subview === "Four0Four" &&
                    <Four0Four.Four0Four {...four0fourProps} />
                  }
                  {/* Footer */}
                  <Components.Footer {...footerProps} />
                </div>
              }
            </div>
          </span>
        }
      </div>
    );
  }
}

/*
 * A full screen loading box that can be used while the app is loading.
 */
export class FullScreenLoading extends React.Component {

  render() {
    return (
      <div className="FullScreenLoading">
        <Components.LoadingIndicator color="dark" size="medium" />
      </div>
    )
  }
}
