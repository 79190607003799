import React from 'react';
import autoBind from 'react-autobind';
// import GoogleLogin from 'react-google-login';

import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
import * as Helpers from '../Helpers.js';

//import the Darwin API classes
import API from '../API.js';

import ImageFacebook from '../images/Icons/FacebookWhite.svg'
import ImageApple from '../images/Icons/AppleLogo.svg'


export class Login extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,
      valid: false, //valid to try submitting?
      verificationCode: false,

      view: "Home", //Home, Code
      error: false, //the error message to display if there is one
    }
    autoBind(this)
  }

  componentDidMount() {
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
  }

  /*
  Generates a one time code for this user.
  */
  generateCode() {
    console.log("Generating Code")
    this.setState({
      loading: true,
    }, () => {
      API.generateUniqueDeviceCodeGrant((result) => {
        console.log("GUDCG", result)
        if ("error" in result) {
          this.setState({
            loading: false
          }, () => {
            this.props.showPopup("Error", "Couldn't generate the code", result.error)
          })
          return
        }
        this.setState({
          loading: false,
          verificationCode: result.data.code,
          verificationState: result.data.state,
        }, () => {
          //Kickoff the checkCode - 10 seconds later the first time.
          setTimeout(this.checkCode, 10000)
        })
      })
    })
  }

  /*
  Checks to see if the verification code has been authenticated.
  */
  checkCode() {
    console.log("Checking Code")
    API.checkUniqueDeviceCodeGrant(this.state.verificationCode, this.state.verificationState, (result) => {
      console.log("Checked Code", result)
      if ("error" in result) {
        if (result.error.includes("Unauthenticated")) {
          //check again in 7 seconds.
          setTimeout(this.checkCode, 7000)
        } else {
          //generate a new code
          this.generateCode()
        }
      } else {
        console.log("SUCCESS! We are logged in!")
        this.userLoggedIn()
      }
    })
  }

  /*
  Call this to login the user with Facebook.
  */
  loginWithFacebook() {
    console.log("Login with Facebook");
    let callback = (response) => {
      this.handleFacebookResponse(response, true)
    }
    window.FB.getLoginStatus(callback);
  }

  /*
  Called when we get a response back from Facebook after the user has logged in.
  */
  handleFacebookResponse(response, callLogin = true) {
    if (response.status === "connected") {
      //the user is logged into Facebook & this app - get the access token.
      let token = response.authResponse.accessToken
      //Trade the Facebook token for Darwin tokens..
      API.loginWithFacebookToken(token, (success, results) => {
        if (success) {
          console.log("User is logged in.")
          this.userLoggedIn()
        } else {
          console.log("Failed to login.", results)
          //Show an error on the screen.
          this.props.showPopup("Error", "Couldn't Login with Facebook", "There was an issue trying to log you into your account with Facebook. Please try again. If the problem persists, reach out to support@wefloww.com")
        }
      })
    } else if (callLogin) {
      //the user needs to login and or authorize our app with Facebook.
      window.FB.login((loginResponse) => {
        this.handleFacebookResponse(loginResponse, false)
      }, {scope: 'public_profile,email'})
    }
  }

  submitPhoneNumber(data) {
    let pn = data["phoneNumber"]
    if (pn.length === 10) {
      pn = "1" + pn
    }
    let pnState = Helpers.randomString(50)
    console.log("Submit phoneNumber", pn)
    this.setState({
      loading: true,
      pnState: pnState,
    }, () => {
      //Submit the phone number for a login code.
      API.phoneNumberAuthenticate(pn, pnState, (result) => {
        if ("error" in result) {
          this.setState({
            loading: false,
            error: result.error
          })
          return
        }
        //success
        this.setState({
          loading: false,
          error: false,
          view: "Code",
        })
      })
    })
  }

  submitCode(data) {
    let phoneNumber = data["phoneNumber"]
    if (phoneNumber.length === 10) {
      phoneNumber = "1" + phoneNumber
    }
    let code = data["code"]
    console.log("Submit code", data)

    this.setState({
      loading: true
    }, () => {
      //Submit the login
      API.manualLogin(phoneNumber, code + "_" + this.state.pnState, (result) => {
        if ("error" in result) {
          this.setState({
            loading: false,
            error: "Invalid code, please try again."
          })
          return
        }
        //user has been logged in.
        this.userLoggedIn()
      })
    })
  }

  /*
  The user has been logged in - get their information and show them their profile or next steps to fill out.
  */
  userLoggedIn() {
    this.props.homeAndReloadUserInfo()
  }

  loginWithApple() {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        this.loginWithApple2()
      }, 20)
    })
  }

  async loginWithApple2() {
    try {
      await Helpers.waitForVar('AppleID');
      //make sure AppleID has been loaded to the window.
      if (!window.AppleID) {
        console.error(new Error('Error loading apple script'));
        return
      }
      //setup the login params
      let nonceParam = Helpers.randomString(12)
      let clientParam = "com.SimpleKicking.SimpleKickingServices"
      let scopeParam = ""
      let redirectParam = "https://account.simplekicking.com/m/login"
      let popupParam = true

      window.AppleID.auth.init({
        clientId: clientParam,
        scope: scopeParam,
        redirectURI: redirectParam,
        nonce: nonceParam,
        usePopup: popupParam,
      });

      //try to login
      const response = await window.AppleID.auth.signIn()
      console.log("Apple Login", response)
      //parse the response and login the user with it.
      API.manualLoginApple(response.authorization.id_token, nonceParam, (result) => {
        if ("error" in result) {
          console.log("Apple Result", result)
          this.setState({
            loading: false,
            error: "Apple error, please try again."
          })
          return
        }
        //user has been logged in.
        this.userLoggedIn()
      })
    } catch (error) {
      //handle error.
      console.error(error)
      this.setState({
        loading: false
      })
    }
  }

  /*
  * Submits the form
  */
  submitForm() {
    let results = this.checkForm(true)
    if (results.valid) {
      switch (this.state.view) {
        case "Home":
          this.submitPhoneNumber(results.data)
          break;
        case "Code":
          this.submitCode(results.data)
          break;
        default:
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
    }
  }

  /*
  Checks the form to make sure it is valid.
  Returns {valid:Bool, data:{}}
  */
  checkForm(print = false) {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        requiredFields = ["phoneNumber"]
        optionals = []
        requiredIf = []
        requiredSometimes = []
        break;
      case "Code":
        requiredFields = ["code", "phoneNumber"]
        optionals = []
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        if (print) {
          console.log("required not filled out: ", element)
        }
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        if (print) {
          console.log("condition field is not filled out: ", condition)
        }
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required and is not filled out: ", condition)
          }
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          if (print) {
            console.log("rif field is required with not and is not filled out: ", condition)
          }
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          if (print) {
            console.log("data not valid", key)
          }
          valid = false
        }
      }
    }

    //3) If valid, return the data, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      return {
        valid: true,
        data: data
      }
    } else {
      return {
        valid: false,
        data: "Form not valid to submit"
      }
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      //check to see if the form is valid to submit
      return {
        data: d,
        forceCheck: false
      }
    }, () => {
      let res = this.checkForm()
      this.setState({
        valid: res.valid
      })
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  render() {

    return (
      <div className="Login">
        <div className="LoginBlock">
          { this.state.view === "Home" &&
            <span>
              { this.state.loading &&
                <div className="FormLoader" style={{height:"168px"}}>
                  <Components.LoadingIndicator color="dark" />
                </div>
              }
              { !this.state.loading &&
                <span>
                  <div className="LoginBlockTitle">
                    Login
                  </div>
                  { this.state.error !== false &&
                    <div className="LoginBlockError">
                      {this.state.error}
                    </div>
                  }
                  {/* Phone Number */}
                  <div className="FormBlockInput">
                    <Components.InputBottomLine type="text" name="phoneNumber" placeholder="" validation="phoneNumber" required="true" title="Phone Number" validateTyping={false}
                      value={this.state.data.phoneNumber ? this.state.data.phoneNumber.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                  </div>
                  <Components.FlowwButton type={this.state.valid ? "Theme" : "Gray"} onClick={this.submitForm} shake={this.state.shakeButton} style={{marginTop:"30px"}}>
                    Continue
                  </Components.FlowwButton>

                  <div className="LoginBlockText">
                    or
                  </div>

                  {/* Login with Facebook */}
                  <div className="LoginBlockButton LoginWithFacebook" onClick={this.loginWithFacebook}>
                    <Components.DualImage image={ImageFacebook} />
                    Sign in with Facebook
                  </div>
                  <br/>

                  {/* Login with Apple */}
                  <div className="LoginBlockButton LoginWithApple" onClick={this.loginWithApple}>
                    <Components.DualImage image={ImageApple} style={{height:"30px"}} />
                    Sign in with Apple
                  </div>
                </span>
              }
            </span>
          }
          { this.state.view === "Code" &&
            <span>
              { this.state.loading &&
                <div className="FormLoader" style={{height:"168px"}}>
                  <Components.LoadingIndicator color="dark" />
                </div>
              }
              { !this.state.loading &&
                <span>
                  <div className="LoginBlockTitle">
                    Enter Code
                  </div>
                  { this.state.error !== false &&
                    <div className="LoginBlockError">
                      {this.state.error}
                    </div>
                  }
                  {/* Code */}
                  <div className="FormBlockInput">
                    <Components.InputBottomLine type="text" name="code" placeholder="" validation="code" required="true" title="One Time Code" validateTyping={false}
                      value={this.state.data.code ? this.state.data.code.value : ""} onEnter={this.submitForm} onChange={this.formChanged} forceCheck={this.state.forceCheck} />
                  </div>
                  <Components.FlowwButton type={this.state.valid ? "Theme" : "Gray"} onClick={this.submitForm} shake={this.state.shakeButton} style={{marginTop:"30px"}}>
                    Submit
                  </Components.FlowwButton>
                </span>
              }
            </span>
          }
        </div>
      </div>
    )
  }
}
