//import the Darwin API classes

import StatViewsCircle from './images/Icons/StatViewsCircle.svg'
import StatTapsCircle from './images/Icons/StatTapsCircle.svg'
import StatFavoritesCircle from './images/Icons/StatFavoritesCircle.svg'
import StatUsesCircle from './images/Icons/StatUsesCircle.svg'
import StatSignedUpCircle from './images/Icons/StatSignedUpCircle.svg'
import StatPayingCircle from './images/Icons/StatPayingCircle.svg'

import { version } from '../../package.json'
import API from './API.js';

export const waitForVar = async (
  name,
  {
    pollFrequency = 1000,
    retries: inRetries = 100,
    parent = window,
  }: {
    pollFrequency?: number | (({ retries: number }) => number),
    retries?: number,
    parent?: Object,
  } = { pollFrequency: 1000, retries: 100, parent: window },
) => {
  // eslint-disable-next-line no-prototype-builtins
  if (parent && parent.hasOwnProperty(name)) {
    return parent[name];
  }
  if (!inRetries) {
    return undefined;
  }
  const retries = inRetries - 1;
  await new Promise((resolve) =>
    setTimeout(
      resolve,
      typeof pollFrequency === 'function'
        ? pollFrequency({ retries })
        : pollFrequency,
    ),
  );
  return waitForVar(name, { pollFrequency, parent, retries });
};

export function getText(id, language = "en") {
  switch (id) {
    case "upgradeAccount": {
      switch (language) {
        case "en":
          return "If you want to send requests for payment, you'll need to verify your account. Verifying your account is free and should take just a few minutes of your time. We only need some basic information about you and/or your company to comply with government regulations and fight fraud."
        default:
          return "Unsupported language for " + id + " and language " + language
      }
    }
    case "personalUnverified": {
      switch (language) {
        case "en":
          return "At this time, personal accounts can't send requests for payment. You'll need to create a business account in order to send requests for payment."
        default:
          return "Unsupported language for " + id + " and language " + language
      }
    }
    case "emptyTransactions": {
      switch (language) {
        case "en":
          return "No Transactions Found."
        default:
          return "Unsupported language for " + id + " and language " + language
      }
    }
    case "emptyInternalUsers": {
      switch (language) {
        case "en":
          return "No Users Found."
        default:
          return "Unsupported language for " + id + " and language " + language
      }
    }
    case "emptyInternalTransactions": {
      switch (language) {
        case "en":
          return "No Transactions Found."
        default:
          return "Unsupported language for " + id + " and language " + language
      }
    }
    default:
      return "Unknown id for getText"
  }
}

//Returns the components of the url.
//For instance, /abc/def/yoyo would return ['abc', 'def', 'yoyo']
//A blank url would return ['']
export function getWindowPathComponents() {
  let path = window.location.pathname
  let pathComponents = path.split("/")
  if (pathComponents[0] === "" && pathComponents.length > 1) {
    //remove the first element as it is worthless.
    pathComponents.splice(0, 1)
  }
  return pathComponents
}

//Changes the url in the browser for help with deep linking.
export function updateWindowPath(newPath) {
  //if the current path is different from newPath then add newPath to the history
  let pathComponents = getWindowPathComponents()
  if ("/" + pathComponents[0] !== newPath) {
    //window.history.replaceState({}, "", newPath)
    window.history.pushState({}, "", newPath)

    //update the page in hubspot
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', newPath]);
    _hsq.push(['trackPageView']);
  }
}

/**
* Returns the url parameters as an object that you can access.
* Code taken from https://www.sitepoint.com/get-url-parameters-with-javascript/
*/
export function getAllUrlParams(url = false) {
  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    let funcv = (v) => {
      paramNum = v.slice(1,-1);
      return '';
    }

    for (var i=0; i<arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // in case params look like: list[]=thing1&list[]=thing2
      var paramNum = undefined;
      var paramName = a[0].replace(/\[\d*\]/, (v) => {
        return funcv(v)
      });

      // set parameter value (use 'true' if empty)
      var paramValue = typeof(a[1])==='undefined' ? true : a[1];

      // if parameter name already exists
      if (obj[paramName]) {
        // convert value to array (if still string)
        if (typeof obj[paramName] === 'string') {
          obj[paramName] = [obj[paramName]];
        }
        // if no array index number specified...
        if (typeof paramNum === 'undefined') {
          // put the value on the end of the array
          obj[paramName].push(paramValue);
        }
        // if array index number specified...
        else {
          // put the value at that index number
          obj[paramName][paramNum] = paramValue;
        }
      }
      // if param name doesn't exist yet, set it
      else {
        obj[paramName] = paramValue;
      }
    }
  }

  //now decode the uri
  let keys = Object.keys(obj)
  for (let i = 0; i < keys.length; i = i + 1) {
    obj[keys[i]] = decodeURIComponent(obj[keys[i]])
  }
  return obj;
}

//Returns if we are in development or production
export function isDevelopment() {
  if (process && process.env && process.env.REACT_APP_STAGE === 'dev') {
    return true
  }
  return false
}

//Returns a random string of the provided length of alphanumeric characters
export function randomString(len) {
  var p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return [...Array(len)].reduce(a=>a+p[~~(Math.random()*p.length)],'');
}

// Subtracts years from a date
export function subtractYears(date, years) {
  let d = date
  d.setFullYear(date.getFullYear() - years)
  return d
}

//Returns the account options.
export function getAccountOptions(accountType) {
  if (accountType === "Personal") {
    return [
      {
        value: "",
        label: "Select an Account Type"
      },
      {
        value: "Personal",
        label: "Personal Account"
      },
    ]
  } else {
    return [
      {
        value: "",
        label: "Select an Account Type"
      },
      {
        value: "Corporation",
        label: "Corporation"
      },
      {
        value: "LLC",
        label: "LLC"
      },
      {
        value: "Partnership",
        label: "Partnership, LP, or LLP"
      },
    ]
  }
}

export function getTransferFailures() {
  return [
    {
      label: "Special Case",
      value: ""
    },
    {
      label: "Insufficient Funds",
      value: "R01"
    },
    {
      label: "Insufficient Funds - Late",
      value: "R01-late"
    },
    /*{
      label: "Bank Account Closed (n, y)",
      value: "R02"
    },*/
    {
      label: "Unable to Locate Account - Remove Bank",
      value: "R03"
    },
    {
      label: "Unable to Locate Account - Late - Remove Bank",
      value: "R03-late"
    },
    /*{
      label: "Invalid Bank Account Number Structure (n, y)",
      value: "R04"
    },
    {
      label: "Unauthorized debit to consumer account (y, y)",
      value: "R05"
    },
    {
      label: "Returned per ODFI's Request (n, n)",
      value: "R06"
    },
    {
      label: "Authorization Revoked by Customer (y, y)",
      value: "R07"
    },
    {
      label: "Payment Stopped (y, y)",
      value: "R08"
    },
    {
      label: "Uncollected Funds (n, n)",
      value: "R09"
    },
    {
      label: "Customer Advises Not Authorized, Improper, or Ineligible (y, y)",
      value: "R10"
    },
    {
      label: "Bank Account Frozen (y, y)",
      value: "R16"
    },
    {
      label: "Non-Transaction Account (y, y)",
      value: "R20"
    },
    {
      label: "Credit Entry Refused by Receiver (n, y)",
      value: "R23"
    },
    {
      label: "Corporate Customer Advises Not Authorized (y, y)",
      value: "R29"
    },*/
    {
      label: "Unlimited Funds - No Balance Checking",
      value: "NBC"
    },
  ]
}

//Returns Business or Personal options.
export function getBusinessOrPersonal() {
  return [
    {
      value: "",
      label: "Select an Account Type"
    },
    {
      value: "Personal",
      label: "Personal"
    },
    {
      value: "Business",
      label: "Business"
    },
  ]
}

export function mapTypeToSubType(type) {
  switch (type) {
    case "Personal":
      return "personal"
    case "Sole Proprietorship":
      return "soleProprietorship"
    case "Unincorporated Association":
      return "soleProprietorship"
    case "Trust":
      return "soleProprietorship"
    case "Corporation":
      return "corporation"
    case "Publicly Traded Corporation":
      return "corporation"
    case "LLC":
      return "llc"
    case "Partnership":
      return "partnership"
    default:
      return "unknown"
  }
}

//Returns the business type options
export function getBusinessTypeOptions() {
  return [
    {
      value: "",
      label: "Select an Account Type"
    },
    {
      value: "corporation",
      label: "Corporation"
    },
    {
      value: "llc",
      label: "LLC"
    },
  ]
}

//Returns table info for displaying request information.
export function getRequestTableInfo() {
  return [
    {
      header: "ID",
      mapping: "display_id",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Client",
      mapping: "client_name",
      classes: "tdHideable2",
      headerClasses: "tdHideable2",
    },
    {
      header: "Amount",
      mapping: "amount",
      classes: "tdMoney",
      headerClasses: "",
    },
    {
      header: "Status",
      mapping: "status",
      classes: "tdStatus",
      headerClasses: "",
    },
    {
      header: "Due Date",
      mapping: "display_due_date",
      classes: "tdHideable",
      headerClasses: "tdHideable",
    },
    {
      header: "Note",
      mapping: "description",
      classes: "tdHideable tdNote",
      headerClasses: "tdHideable",
    }
  ]
}

//Returns table info for displaying internal user information.
export function getUserTableInfo() {
  return [
    {
      header: "First Name",
      mapping: "short_name",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Last Name",
      mapping: "last_name",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Email",
      mapping: "email",
      classes: "tuHideable",
      headerClasses: "tuHideable",
    },
    {
      header: "Status",
      mapping: "dwolla_status",
      classes: "tdStatus",
      headerClasses: "",
    },
    {
      header: "Last Use",
      mapping: "display_last_use",
      classes: "tuHideable",
      headerClasses: "tuHideable",
    },
    {
      header: "Business",
      mapping: "business_name",
      classes: "tuHideable2",
      headerClasses: "tuHideable2",
    }
  ]
}

//Returns table info for displaying internal transactions information.
export function getTransactionsTableInfo() {
  return [
    {
      header: "Vendor",
      mapping: "name",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Client",
      mapping: "client_name",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Amount",
      mapping: "amount",
      classes: "tuHideable2",
      headerClasses: "tuHideable2",
    },
    {
      header: "Status",
      mapping: "status",
      classes: "tdStatus",
      headerClasses: "",
    },
    {
      header: "Last Action",
      mapping: "last_action",
      classes: "tuHideable",
      headerClasses: "tuHideable",
      format: (value) => {
        return formatDateObjectLong(parseSQLDate(value))
      }
    },
    {
      header: "Description",
      mapping: "description",
      classes: "tuHideable",
      headerClasses: "tuHideable",
    }
  ]
}

//Returns table info for displaying security sessions.
export function getSecuritySessionsTableInfo() {
  return [
    {
      header: "Device",
      mapping: "operating_system",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Browser",
      mapping: "browser",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Location",
      mapping: "location",
      classes: "tsHideable",
      headerClasses: "tsHideable",
    },
    {
      header: "Time",
      mapping: "time",
      classes: "tsHideable2",
      headerClasses: "tsHideable2",
      format: (value) => {
        return formatDateObjectLong(parseSQLDate(value))
      }
    },
  ]
}

//Returns table info for sending request information.
export function getSendingTableInfo() {
  return [
    {
      header: "ID",
      mapping: "display_id",
      classes: "",
      headerClasses: "",
    },
    {
      header: "Vendor",
      mapping: "vendorName",
      classes: "tdHideable2",
      headerClasses: "tdHideable2",
    },
    {
      header: "Amount",
      mapping: "amount",
      classes: "tdMoney",
      headerClasses: "",
    },
    {
      header: "Status",
      mapping: "status",
      classes: "tdStatus",
      headerClasses: "",
    },
    {
      header: "Due Date",
      mapping: "display_due_date",
      classes: "tdHideable",
      headerClasses: "tdHideable",
    },
    {
      header: "Note",
      mapping: "description",
      classes: "tdHideable tdNote",
      headerClasses: "tdHideable",
    }
  ]
}

//Appends and saves the requestID to the list of requests to link for this user when they login.
export function appendRequest(requestID) {
  let requests = API.getCookie("ZochaRequests")
  if (requests === "") {
    requests = []
  } else {
    try {
      requests = JSON.parse(requests)
    } catch (e) {
      requests = []
    }
  }
  if (!requests.includes(requestID)) {
    requests.push(requestID)
  }
  API.setCookie("ZochaRequests", JSON.stringify(requests))
}

//Returns the requests for the user that they have linked via email.
export function getRequests() {
  let requests = API.getCookie("ZochaRequests")
  if (requests === "") {
    requests = []
  } else {
    try {
      requests = JSON.parse(requests)
    } catch (e) {
      requests = []
    }
  }
  return requests
}

//Clears out the requests.
export function clearRequests() {
  API.deleteCookie("ZochaRequests")
}

//Returns the document types for the provided accountType and who the document is for.
//documentFor can be personal, controller, or business
export function getDocumentTypes(accountType, documentFor) {
  let data = [
    {
      value: "",
      label: "Select a Document Type"
    }
  ]
  if (documentFor === "personal" || documentFor === "controller") {
    data.push({
      value: "passport",
      label: "Passport"
    })
    data.push({
      value: "license",
      label: "License"
    })
    data.push({
      value: "idCard",
      label: "ID Card"
    })
    return data
  }
  //business document
  switch (accountType) {
    case "Sole Proprietorship":
    case "Unincorporated Association":
    case "Trust":
      data.push({
        value: "other_1",
        label: "EIN Letter from IRS"
      })
      data.push({
        value: "other_2",
        label: "Business License"
      })
      data.push({
        value: "other_3",
        label: "Sales/Use Tax License"
      })
      data.push({
        value: "other_4",
        label: "Registration of Trade Name"
      })
      data.push({
        value: "other_5",
        label: "Filed and stamped Articles of Organization/Incorporation"
      })
      data.push({
        value: "other_6",
        label: "Certificate of Good Standing"
      })
      data.push({
        value: "other_7",
        label: "Other US Government Issued Document"
      })
      break;
    case "Corporation":
    case "Publicly Traded Company":
    case "Non-Profit":
    case "LLC":
    case "Partnership":
      data.push({
        value: "other_1",
        label: "EIN Letter from IRS"
      })
      data.push({
        value: "other_7",
        label: "Other US Government Issued Document"
      })
      break;
    default:
      break;
  }
  return data
}

//Returns the list of states for a form.
export function getStates() {
  return [
    { value: "", label: "Select a State"},
    { value: 'AL', label: 'Alabama'},
    { value: 'AK', label: 'Alaska'},
    { value: 'AS', label: 'American Samoa'},
    { value: 'AZ', label: 'Arizona'},
    { value: 'AR', label: 'Arkansas'},
    { value: 'CA', label: 'California'},
    { value: 'CO', label: 'Colorado'},
    { value: 'CT', label: 'Connecticut'},
    { value: 'DE', label: 'Delaware'},
    { value: 'DC', label: 'District of Columbia'},
    { value: 'FM', label: 'Federated States of Micronesia'},
    { value: 'FL', label: 'Florida'},
    { value: 'GA', label: 'Georgia'},
    { value: 'GU', label: 'Guam'},
    { value: 'HI', label: 'Hawaii'},
    { value: 'ID', label: 'Idaho'},
    { value: 'IL', label: 'Illinois'},
    { value: 'IN', label: 'Indiana'},
    { value: 'IA', label: 'Iowa'},
    { value: 'KS', label: 'Kansas'},
    { value: 'KY', label: 'Kentucky'},
    { value: 'LA', label: 'Louisiana'},
    { value: 'ME', label: 'Maine'},
    { value: 'MH', label: 'Marshall Islands'},
    { value: 'MD', label: 'Maryland'},
    { value: 'MA', label: 'Massachusetts'},
    { value: 'MI', label: 'Michigan'},
    { value: 'MN', label: 'Minnesota'},
    { value: 'MS', label: 'Mississippi'},
    { value: 'MO', label: 'Missouri'},
    { value: 'MT', label: 'Montana'},
    { value: 'NE', label: 'Nebraska'},
    { value: 'NV', label: 'Nevada'},
    { value: 'NH', label: 'New Hampshire'},
    { value: 'NJ', label: 'New Jersey'},
    { value: 'NM', label: 'New Mexico'},
    { value: 'NY', label: 'New York'},
    { value: 'NC', label: 'North Carolina'},
    { value: 'ND', label: 'North Dakota'},
    { value: 'MP', label: 'Northern Mariana Islands'},
    { value: 'OH', label: 'Ohio'},
    { value: 'OK', label: 'Oklahoma'},
    { value: 'OR', label: 'Oregon'},
    { value: 'PW', label: 'Palau'},
    { value: 'PA', label: 'Pennsylvania'},
    { value: 'PR', label: 'Puerto Rico'},
    { value: 'RI', label: 'Rhode Island'},
    { value: 'SC', label: 'South Carolina'},
    { value: 'SD', label: 'South Dakota'},
    { value: 'TN', label: 'Tennessee'},
    { value: 'TX', label: 'Texas'},
    { value: 'UT', label: 'Utah'},
    { value: 'VT', label: 'Vermont'},
    { value: 'VI', label: 'Virgin Islands'},
    { value: 'VA', label: 'Virginia'},
    { value: 'WA', label: 'Washington'},
    { value: 'WV', label: 'West Virginia'},
    { value: 'WI', label: 'Wisconsin'},
    { value: 'WY', label: 'Wyoming'},
    { value: 'AE', label: 'Armed Forces Europe, the Middle East, and Canada'},
    { value: 'AP', label: 'Armed Forces Pacific'},
    { value: 'AA', label: 'Armed Forces Americas (except Canada)'}
  ]
}

//Returns the list of states for a form.
export function getCountries(excludeUS = false) {
  let data = [
    { value: "", label: "Select a Country"},
    { value: 'US', label: 'United States of America'},
    { value: 'AD', label: 'Andorra'},
    { value: 'AE', label: 'United Arab Emirates'},
    { value: 'AF', label: 'Afghanistan'},
    { value: 'AG', label: 'Antigua and Barbuda'},
    { value: 'AI', label: 'Anguilla'},
    { value: 'AL', label: 'Albania'},
    { value: 'AM', label: 'Armenia'},
    { value: 'AO', label: 'Angola'},
    { value: 'AQ', label: 'Antarctica'},
    { value: 'AR', label: 'Argentina'},
    { value: 'AS', label: 'American Samoa'},
    { value: 'AT', label: 'Austria'},
    { value: 'AU', label: 'Australia'},
    { value: 'AW', label: 'Aruba'},
    { value: 'AX', label: 'Åland Islands'},
    { value: 'AZ', label: 'Azerbaijan'},
    { value: 'BA', label: 'Bosnia and Herzegovina'},
    { value: 'BB', label: 'Barbados'},
    { value: 'BD', label: 'Bangladesh'},
    { value: 'BE', label: 'Belgium'},
    { value: 'BF', label: 'Burkina Faso'},
    { value: 'BG', label: 'Bulgaria'},
    { value: 'BH', label: 'Bahrain'},
    { value: 'BI', label: 'Burundi'},
    { value: 'BJ', label: 'Benin'},
    { value: 'BL', label: 'Saint Barthélemy'},
    { value: 'BM', label: 'Bermuda'},
    { value: 'BN', label: 'Brunei Darussalam'},
    { value: 'BO', label: 'Bolivia (Plurinational State of)'},
    { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba'},
    { value: 'BR', label: 'Brazil'},
    { value: 'BS', label: 'Bahamas'},
    { value: 'BT', label: 'Bhutan'},
    { value: 'BV', label: 'Bouvet Island'},
    { value: 'BW', label: 'Botswana'},
    { value: 'BY', label: 'Belarus'},
    { value: 'BZ', label: 'Belize'},
    { value: 'CA', label: 'Canada'},
    { value: 'CC', label: 'Cocos (Keeling) Islands'},
    { value: 'CD', label: 'Congo, Democratic Republic of the'},
    { value: 'CF', label: 'Central African Republic'},
    { value: 'CG', label: 'Congo'},
    { value: 'CH', label: 'Switzerland'},
    { value: 'CI', label: "Côte d'Ivoire"},
    { value: 'CK', label: 'Cook Islands'},
    { value: 'CL', label: 'Chile'},
    { value: 'CM', label: 'Cameroon'},
    { value: 'CN', label: 'China'},
    { value: 'CO', label: 'Colombia'},
    { value: 'CR', label: 'Costa Rica'},
    { value: 'CU', label: 'Cuba'},
    { value: 'CV', label: 'Cabo Verde'},
    { value: 'CW', label: 'Curaçao'},
    { value: 'CX', label: 'Christmas Island'},
    { value: 'CY', label: 'Cyprus'},
    { value: 'CZ', label: 'Czechia'},
    { value: 'DE', label: 'Germany'},
    { value: 'DJ', label: 'Djibouti'},
    { value: 'DK', label: 'Denmark'},
    { value: 'DM', label: 'Dominica'},
    { value: 'DO', label: 'Dominican Republic'},
    { value: 'DZ', label: 'Algeria'},
    { value: 'EC', label: 'Ecuador'},
    { value: 'EE', label: 'Estonia'},
    { value: 'EG', label: 'Egypt'},
    { value: 'EH', label: 'Western Sahara'},
    { value: 'ER', label: 'Eritrea'},
    { value: 'ES', label: 'Spain'},
    { value: 'ET', label: 'Ethiopia'},
    { value: 'FI', label: 'Finland'},
    { value: 'FJ', label: 'Fiji'},
    { value: 'FK', label: 'Falkland Islands (Malvinas)'},
    { value: 'FM', label: 'Micronesia (Federated States of)'},
    { value: 'FO', label: 'Faroe Islands'},
    { value: 'FR', label: 'France'},
    { value: 'GA', label: 'Gabon'},
    { value: 'GB', label: 'United Kingdom of Great Britain and Northern Ireland'},
    { value: 'GD', label: 'Grenada'},
    { value: 'GE', label: 'Georgia'},
    { value: 'GF', label: 'French Guiana'},
    { value: 'GG', label: 'Guernsey'},
    { value: 'GH', label: 'Ghana'},
    { value: 'GI', label: 'Gibraltar'},
    { value: 'GL', label: 'Greenland'},
    { value: 'GM', label: 'Gambia'},
    { value: 'GN', label: 'Guinea'},
    { value: 'GP', label: 'Guadeloupe'},
    { value: 'GQ', label: 'Equatorial Guinea'},
    { value: 'GR', label: 'Greece'},
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands'},
    { value: 'GT', label: 'Guatemala'},
    { value: 'GU', label: 'Guam'},
    { value: 'GW', label: 'Guinea-Bissau'},
    { value: 'GY', label: 'Guyana'},
    { value: 'HK', label: 'Hong Kong'},
    { value: 'HM', label: 'Heard Island and McDonald Islands'},
    { value: 'HN', label: 'Honduras'},
    { value: 'HR', label: 'Croatia'},
    { value: 'HT', label: 'Haiti'},
    { value: 'HU', label: 'Hungary'},
    { value: 'ID', label: 'Indonesia'},
    { value: 'IE', label: 'Ireland'},
    { value: 'IL', label: 'Israel'},
    { value: 'IM', label: 'Isle of Man'},
    { value: 'IN', label: 'India'},
    { value: 'IO', label: 'British Indian Ocean Territory'},
    { value: 'IQ', label: 'Iraq'},
    { value: 'IR', label: 'Iran (Islamic Republic of)'},
    { value: 'IS', label: 'Iceland'},
    { value: 'IT', label: 'Italy'},
    { value: 'JE', label: 'Jersey'},
    { value: 'JM', label: 'Jamaica'},
    { value: 'JO', label: 'Jordan'},
    { value: 'JP', label: 'Japan'},
    { value: 'KE', label: 'Kenya'},
    { value: 'KG', label: 'Kyrgyzstan'},
    { value: 'KH', label: 'Cambodia'},
    { value: 'KI', label: 'Kiribati'},
    { value: 'KM', label: 'Comoros'},
    { value: 'KN', label: 'Saint Kitts and Nevis'},
    { value: 'KP', label: "Korea (Democratic People's Republic of)"},
    { value: 'KR', label: 'Korea, Republic of'},
    { value: 'KW', label: 'Kuwait'},
    { value: 'KY', label: 'Cayman Islands'},
    { value: 'KZ', label: 'Kazakhstan'},
    { value: 'LA', label: "Lao People's Democratic Republic"},
    { value: 'LB', label: 'Lebanon'},
    { value: 'LC', label: 'Saint Lucia'},
    { value: 'LI', label: 'Liechtenstein'},
    { value: 'LK', label: 'Sri Lanka'},
    { value: 'LR', label: 'Liberia'},
    { value: 'LS', label: 'Lesotho'},
    { value: 'LT', label: 'Lithuania'},
    { value: 'LU', label: 'Luxembourg'},
    { value: 'LV', label: 'Latvia'},
    { value: 'LY', label: 'Libya'},
    { value: 'MA', label: 'Morocco'},
    { value: 'MC', label: 'Monaco'},
    { value: 'MD', label: 'Moldova, Republic of'},
    { value: 'ME', label: 'Montenegro'},
    { value: 'MF', label: 'Saint Martin (French part)'},
    { value: 'MG', label: 'Madagascar'},
    { value: 'MH', label: 'Marshall Islands'},
    { value: 'MK', label: 'North Macedonia'},
    { value: 'ML', label: 'Mali'},
    { value: 'MM', label: 'Myanmar'},
    { value: 'MN', label: 'Mongolia'},
    { value: 'MO', label: 'Macao'},
    { value: 'MP', label: 'Northern Mariana Islands'},
    { value: 'MQ', label: 'Martinique'},
    { value: 'MR', label: 'Mauritania'},
    { value: 'MS', label: 'Montserrat'},
    { value: 'MT', label: 'Malta'},
    { value: 'MU', label: 'Mauritius'},
    { value: 'MV', label: 'Maldives'},
    { value: 'MW', label: 'Malawi'},
    { value: 'MX', label: 'Mexico'},
    { value: 'MY', label: 'Malaysia'},
    { value: 'MZ', label: 'Mozambique'},
    { value: 'NA', label: 'Namibia'},
    { value: 'NC', label: 'New Caledonia'},
    { value: 'NE', label: 'Niger'},
    { value: 'NF', label: 'Norfolk Island'},
    { value: 'NG', label: 'Nigeria'},
    { value: 'NI', label: 'Nicaragua'},
    { value: 'NL', label: 'Netherlands'},
    { value: 'NO', label: 'Norway'},
    { value: 'NP', label: 'Nepal'},
    { value: 'NR', label: 'Nauru'},
    { value: 'NU', label: 'Niue'},
    { value: 'NZ', label: 'New Zealand'},
    { value: 'OM', label: 'Oman'},
    { value: 'PA', label: 'Panama'},
    { value: 'PE', label: 'Peru'},
    { value: 'PF', label: 'French Polynesia'},
    { value: 'PG', label: 'Papua New Guinea'},
    { value: 'PH', label: 'Philippines'},
    { value: 'PK', label: 'Pakistan'},
    { value: 'PL', label: 'Poland'},
    { value: 'PM', label: 'Saint Pierre and Miquelon'},
    { value: 'PN', label: 'Pitcairn'},
    { value: 'PR', label: 'Puerto Rico'},
    { value: 'PS', label: 'Palestine, State of'},
    { value: 'PT', label: 'Portugal'},
    { value: 'PW', label: 'Palau'},
    { value: 'PY', label: 'Paraguay'},
    { value: 'QA', label: 'Qatar'},
    { value: 'RE', label: 'Réunion'},
    { value: 'RO', label: 'Romania'},
    { value: 'RS', label: 'Serbia'},
    { value: 'RU', label: 'Russian Federation'},
    { value: 'RW', label: 'Rwanda'},
    { value: 'SA', label: 'Saudi Arabia'},
    { value: 'SB', label: 'Solomon Islands'},
    { value: 'SC', label: 'Seychelles'},
    { value: 'SD', label: 'Sudan'},
    { value: 'SE', label: 'Sweden'},
    { value: 'SG', label: 'Singapore'},
    { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha'},
    { value: 'SI', label: 'Slovenia'},
    { value: 'SJ', label: 'Svalbard and Jan Mayen'},
    { value: 'SK', label: 'Slovakia'},
    { value: 'SL', label: 'Sierra Leone'},
    { value: 'SM', label: 'San Marino'},
    { value: 'SN', label: 'Senegal'},
    { value: 'SO', label: 'Somalia'},
    { value: 'SR', label: 'Suriname'},
    { value: 'SS', label: 'South Sudan'},
    { value: 'ST', label: 'Sao Tome and Principe'},
    { value: 'SV', label: 'El Salvador'},
    { value: 'SX', label: 'Sint Maarten (Dutch part)'},
    { value: 'SY', label: 'Syrian Arab Republic'},
    { value: 'SZ', label: 'Eswatini'},
    { value: 'TC', label: 'Turks and Caicos Islands'},
    { value: 'TD', label: 'Chad'},
    { value: 'TF', label: 'French Southern Territories'},
    { value: 'TG', label: 'Togo'},
    { value: 'TH', label: 'Thailand'},
    { value: 'TJ', label: 'Tajikistan'},
    { value: 'TK', label: 'Tokelau'},
    { value: 'TL', label: 'Timor-Leste'},
    { value: 'TM', label: 'Turkmenistan'},
    { value: 'TN', label: 'Tunisia'},
    { value: 'TO', label: 'Tonga'},
    { value: 'TR', label: 'Turkey'},
    { value: 'TT', label: 'Trinidad and Tobago'},
    { value: 'TV', label: 'Tuvalu'},
    { value: 'TW', label: 'Taiwan, Province of China'},
    { value: 'TZ', label: 'Tanzania, United Republic of'},
    { value: 'UA', label: 'Ukraine'},
    { value: 'UG', label: 'Uganda'},
    { value: 'UM', label: 'United States Minor Outlying Islands'},
    { value: 'UY', label: 'Uruguay'},
    { value: 'UZ', label: 'Uzbekistan'},
    { value: 'VA', label: 'Holy See'},
    { value: 'VC', label: 'Saint Vincent and the Grenadines'},
    { value: 'VE', label: 'Venezuela (Bolivarian Republic of)'},
    { value: 'VG', label: 'Virgin Islands (British)'},
    { value: 'VI', label: 'Virgin Islands (U.S.)'},
    { value: 'VN', label: 'Viet Nam'},
    { value: 'VU', label: 'Vanuatu'},
    { value: 'WF', label: 'Wallis and Futuna'},
    { value: 'WS', label: 'Samoa'},
    { value: 'YE', label: 'Yemen'},
    { value: 'YT', label: 'Mayotte'},
    { value: 'ZA', label: 'South Africa'},
    { value: 'ZM', label: 'Zambia'},
    { value: 'ZW', label: 'Zimbabwe'},
  ]
  if (excludeUS) {
    data.splice(1,1)
  }
  return data
}

//Returns true or false if there is a valid bank account for the user
export function bankAccountValid(sources) {
  for (let i = 0; i < sources.length; i = i + 1) {
    let source = sources[i]
    if (source.type === "bank" && source.status === "verified" && !source.removed && source.zocha && source.zocha.status === "default" && source.zocha.verification_status === "verified") {
      return true
    }
  }
  return false
}

//Returns true or false if you need to open Plaid to edit your bank account
export function bankAccountEditing(sources) {
  for (let i = 0; i < sources.length; i = i + 1) {
    let source = sources[i]
    if (source.zocha && source.zocha.status === "default" && source.zocha.verification_status === "pending_manual_verification") {
      return true
    }
  }
  return false
}

//Returns true or false if you are waiting on automatic verification
export function bankAccountWaiting(sources) {
  for (let i = 0; i < sources.length; i = i + 1) {
    let source = sources[i]
    if (source.zocha && source.zocha.status === "default" && source.zocha.verification_status === "pending_automatic_verification") {
      return true
    }
  }
  return false
}

//Finds and returns the default bank account or returns false
export function getDefaultBankAccount(sources) {
  for (let i = 0; i < sources.length; i = i + 1) {
    let source = sources[i]
    if (source.type === "bank" && source.status === "verified" && !source.removed && source.zocha && source.zocha.status === "default" && source.zocha.verification_status === "verified") {
      return source
    }
  }
  return false
}

//Returns a display name for the default bank account.
export function getDefaultBankAccountName(sources) {
  let defaultBankAccount = getDefaultBankAccount(sources)
  if (defaultBankAccount === false) {
    return "****"
  }
  return ((defaultBankAccount.zocha.institution_name ? (defaultBankAccount.zocha.institution_name + "  - ") : "") + defaultBankAccount.zocha.account_name)
}

//Returns if this user's account has been upgraded, is in the process of upgrading, or has not been upgraded.
//Returns one of "upgraded", "upgrading", "basic"
export function accountUpgraded(userInfo) {
  if (userInfo.dwollaAccount.status === "unverified") {
    //hasn't started the process.
    if (userInfo.user.customer_form === null) {
      return "basic"
    } else {
      //the form is set, we are waiting on review
      return "upgrading"
    }
  } else {
    //Check status
    if (userInfo.dwollaAccount.status === "suspended" || userInfo.dwollaAccount.status === "deactivated") {
      //The account has been suspended.
      return "basic"
    } else if (userInfo.dwollaAccount.status === "retry") {
      //Retry sending in the account information.
      return "upgrading"
    } else if (userInfo.dwollaAccount.status === "document") {
      //check if the documents property is set or we are waiting/rejected/need another doc.
      return "upgrading"
    } else if (userInfo.dwollaAccount._links["certify-beneficial-ownership"]) {
      //Customer is Dwolla verified, now working on beneficial owners and the need to certify.
      return "upgrading"
    } else {
      //account has been upgraded
      return "upgraded"
    }
  }
}

export function getStatImage(statistic) {
  switch (statistic) {
    case "Views":
      return StatViewsCircle
    case "Taps":
      return StatTapsCircle
    case "Favorites":
      return StatFavoritesCircle
    case "Uses":
      return StatUsesCircle
    case "Signed Up":
      return StatSignedUpCircle
    case "Paying":
      return StatPayingCircle
    default:
      return StatViewsCircle
  }
}

export function getStatHelpDescription(type) {
  let helpDescription = ""
  switch (type) {
    case "Views":
      helpDescription = "How many times users have swiped over and seen your deal."
      break;
    case "Taps":
      helpDescription = "How many times users have tapped on your deal to expand its details."
      break;
    case "Favorites":
      helpDescription = "How many times users have favorited your deal."
      break;
    case "Uses":
      helpDescription = "How many times users have used your deal."
      break;
    case "Signed Up":
      helpDescription = "How many shops or accounts this Ambassador has signed up."
      break;
    case "Paying":
      helpDescription = "How many of the shops or accounts that this Ambassador has signed up are paying."
      break;
    default:
      break
  }
  return helpDescription
}

// Parse string like '2019-09-12 09:52:52.992823+00'
// to a JS Date
// Assumes string is always +00
export function parseSQLDate(s) {
  if (s === false || s === null || s === undefined) {
    return new Date();
  }
  let b = s.split(/\D/);
  --b[1];                  // Adjust month number
  b[6] = b[6].substr(0,3); // Microseconds to milliseconds
  return new Date(Date.UTC(...b));
}

//Formats a Date object to a long format like Dec 23, 2019 2:30 PM
export function formatDateObjectLong(date) {
  //now format the date for output
  let longFormat = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    //timeZoneName: 'long'
  };
  return date.toLocaleDateString("en-US", longFormat)
}

//Formats a Date object to a short format like Dec 23, 2019
export function formatDateObjectShort(date) {
  //now format the date for output
  let longFormat = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };
  return date.toLocaleDateString("en-US", longFormat)
}

//Formats a date to a string format of: Dec 23, 2019
export function formatDate(date) {
  let t = date.split(/[- :]/)
  let d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]))
  //now format the date for output
  let logDateExpandedFormat = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };
  return d.toLocaleDateString("en-US", logDateExpandedFormat)
}

export function formatDateShort(date) {
  let t = date.split(/[- :]/)
  let d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]))
  //now format the date for output
  let logDateExpandedFormat = {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  };
  let dd = d.toLocaleDateString("en-US", logDateExpandedFormat)
  return dd
}

export function formatDateTime(date) {
  return Intl.DateTimeFormat(undefined, {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date)
}

export function parsePhoneNumber(phoneNumber) {
  //parse this into a phone number with (), spaces, and dashes
  //phoneNumber = phoneNumber.substring(0, 10)
  let index = 0
  if (phoneNumber.length > 10) {
    index = phoneNumber.length - 10
  }
  let part0 = phoneNumber.substring(0, index)
  let part1 = phoneNumber.substring(index, index + 3)
  let part2 = phoneNumber.substring(index + 3, index + 6)
  let part3 = phoneNumber.substring(index + 6)

  let value = ""
  if (part0.length > 0) {
    value += part0 + " "
  }
  value += "(" + part1
  if (part1.length === 3 && part2.length > 0) {
    value += ") "
  }
  value += part2
  if (part2.length === 3 && part3.length > 0) {
    value += " - "
  }
  value += part3

  return value
}

export function parseEIN(ein) {
  //parse this into an EIN like 12-3456789
  let value = ein
  if (ein.length > 2) {
    value = ein.substring(0, 2) + "-" + ein.substring(2, 9)
  }
  return value
}

/*
Parses the input into a value to pass for a form.
*/
export function parseSSN4(ssn) {
  //parse this into a SSN4 like XXX-XX-1234 into 1234
  let value = ssn
  if (value.length > 0) {
    value = ssn.substring(7, 11)
  }
  return value
}

/*
Formats the full SSN for display
*/
export function formatSSN(ssn) {
  let value = ""
  if (ssn.length > 0) {
    value += ssn.substring(0, 3)
  }
  if (ssn.length > 3) {
    value += "-" + ssn.substring(3, 5)
  }
  if (ssn.length > 5) {
    value += "-" + ssn.substring(5)
  }
  return value
}

export function parseZip(zip) {
  //parse this into a zip like 12345-1234
  let value = zip.replace(/[^0-9]/g, '')
  if (value.length > 5) {
    value = value.substring(0, 5) + "-" + value.substring(5, 9)
  }
  return value
}

export function formatNumber(numb) {
  if (numb === undefined) {
    return ""
  }
  return numb.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

export function formatNumberOnlyInts(numb) {
  if (numb % 1 !== 0) {
    return ''
  }
  return numb + ''
}

export function formatNumberDecimals(numb, decimals = 2) {
  if (numb === undefined) {
    return ""
  }
  let j = parseFloat(numb).toFixed(decimals)
  return j.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function padNumber(num, places) {
  let zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

export function getAppVersion() {
  return version
}

export function dateInPast(dateString) {
  if (dateString === null || dateString === undefined || dateString === false) {
    return false
  }
  let t = dateString.split(/[- :]/)
  let d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]))

  let today = new Date();
  if (today > d) {
    return true
  } else {
    return false
  }
}

/*
Parses a Google Address and returns the components for form usage.
*/
export function parseGoogleAddress(address) {
  let address1 = ""
  let city = ""
  let state = ""
  let zip = ""
  let country = ""
  for (let i = 0; i < address[0].address_components.length; i = i + 1) {
    let comp = address[0].address_components[i]
    if (comp.types.includes("street_number") || comp.types.includes("route")) {
      if (address1.length > 0) {
        address1 += " " + comp.long_name
      } else {
        address1 += comp.long_name
      }
    }
    if (comp.types.includes("locality")) {
      city += comp.long_name
    }
    if (comp.types.includes("political") && comp.types.includes("administrative_area_level_1")) {
      state += comp.short_name
    }
    if (comp.types.includes("postal_code")) {
      zip += comp.long_name
    }
    if (comp.types.includes("country")) {
      country += comp.short_name
    }
  }

  return {
    address1: address1,
    city: city,
    state: state,
    zip: zip,
    country: country
  }
}

/*
Returns whether or not we are on a mobile (touch) device
*/
export function isMobile() {
  var check = false;
  (function(a){if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0,4))) check = true;})(navigator.userAgent||navigator.vendor||window.opera);
  return check;
}
